// import firebase from 'firebase/app'
// import 'firebase/database'
// import 'firebase/auth'
// mport firebase from 'firebase/app'
import { db } from '@/main'
// import MolaMfpAPI from '@/clients/MolaMfpAPI'
const FirebaseModule = {
  state: {
    eventList: [],
    eventListEmpty: false,
    fighterList: [],
    fighterListEmpty: false,
    fightTypesArr: [],
    userPredictionsArr: [],
    userPredictionsEmpty: false,
    allTimeRewardLeaderboard: [],
    allTimeLeaderboard: [],
    allTimeLBEmpty: false,
    weeklyRewardLeaderboard: [],
    weeklyLeaderboard: [],
    weeklyLBEmpty: false,
    liveLeaderboard: [],
    leaderboardDetail: [],
    weeklyLeaderboardIds: [],
  },
  mutations: {
    setEventList(state, payload) {
      state.eventList = payload;
    },
    setEventListEmpty(state, payload) {
      state.eventListEmpty = payload;
    },
    setFighterList(state, payload) {
      state.fighterList = payload;
    },
    setFighterListEmpty(state, payload) {
      state.fighterListEmpty = payload;
    },
    setFightTypesArr(state, payload) {
      state.fightTypesArr = payload;
    },
    setUserPredictionsArr(state, payload) {
      state.userPredictionsArr = payload;
    },
    setAllTimeRewardLeaderboard(state, payload) {
      state.allTimeRewardLeaderboard = payload;
    },
    setAllTimeLeaderboard(state, payload) {
      state.allTimeLeaderboard = payload;
    },
    setAllTimeLBEmpty(state, payload) {
      state.allTimeLBEmpty = payload;
    },
    setWeeklyRewardLeaderboard(state, payload) {
      state.weeklyRewardLeaderboard = payload;
    },
    setWeeklyLeaderboard(state, payload) {
      state.weeklyLeaderboard = payload;
    },
    setWeeklyLBEmpty(state, payload) {
      state.weeklyLBEmpty = payload;
    },
    setUserPredictionsEmpty(state, payload) {
      state.userPredictionsEmpty = payload;
    },
    setLiveLeaderboard(state, payload) {
      state.liveLeaderboard = payload;
    },
    setLeaderboardDetail(state, payload) {
      state.leaderboardDetail = payload;
    },
    setWeeklyLeaderboardIds(state, payload) {
      state.weeklyLeaderboardIds = payload;
    },
  },
  actions: {
    getEventList({ commit }, payload) {
      commit('setLoading', payload.limit);
      let today = new Date().getTime();
      // console.log(today)
      let query = db
        .collection('events')
        .where('status', '==', 1) // check when status is 2
        .where('event_pub_start', '<', today)
        .orderBy('event_pub_start', 'asc')
        .limit(payload.limit);
      // console.log(query)
      query
        .get()
        .then((snapshot) => {
          if (snapshot.empty) {
            console.log('No matching Events.');
            commit('setEventListEmpty', true);
            return;
          }
          console.log('snapshot.empty');
          console.log(snapshot.empty);
          let index = 0;
          var eventList = [];
          snapshot.forEach((doc) => {
            // console.log(doc.id, '=>', doc.data())
            var obj = doc.data();
            obj.doc_id = doc.id;
            obj.checkTime = Math.round(today / 1000 + 1800); // 1 Hour to prevent to much Firebase loading/reading
            obj.fights = [];
            // var inArray = eventList.findIndex(item => item.id === obj.id) > -1
            if (
              today > doc.data().event_pub_start &&
              today < doc.data().event_pub_end
            ) {
              // today > doc.data().publish &&
              eventList.push(obj);
              let query = db
                .collection('events')
                .doc(doc.id)
                .collection('fights')
                .orderBy('start', 'asc'); 
              query.get().then(
                (snapshot) => {
                  console.log(
                    `Received query snapshot of size ${snapshot.size}`
                  );
                  if (snapshot.empty) {
                    console.log('No matching documents.');
                    this.loadingData = false;
                    return;
                  }
                  // console.log(index)
                  eventList[index].fights = [];
                  snapshot.forEach((doc) => {
                    // console.log(doc.id, '=>', doc.data())
                    var obj = doc.data();
                    obj.id = doc.id;
                    obj.predictionInvalid = false;
                    obj.predictionSubmitted = false;
                    obj.changePrediction = false;
                    obj.loading = false;
                    // calculate predictions
                    let homePredictions = doc.data().home.predictions_count; // !! Update SOON
                    let awayPredictions = doc.data().away.predictions_count;
                    let totalHome =
                      homePredictions === 0
                        ? 0
                        : Math.round(
                            (100 / (homePredictions + awayPredictions)) *
                              homePredictions
                          );
                    let totalAway =
                      awayPredictions === 0
                        ? 0
                        : Math.round(
                            (100 / (homePredictions + awayPredictions)) *
                              awayPredictions
                          );
                    obj.totalHome = totalHome;
                    obj.totalAway = totalAway;
                    // console.log(totalHome, totalAway)
                    eventList[index].fights.push(obj);
                  });
                  eventList[index].fights.sort(function (a, b) {
                    // Turn your strings into dates, and then subtract them
                    // to get a value that is either negative, positive, or zero.
                    return new Date(b.start) - new Date(a.start);
                  });
                  index++;
                  // console.log(this.news)
                  // ...
                },
                (err) => {
                  console.log(`Encountered error: ${err}`);
                }
              );
              commit('setEventListEmpty', false);
            }
          });
          console.log('############ eventList #############');
          console.log(eventList);
          if (eventList.length === 0) commit('setEventListEmpty', true);
          commit('setEventList', eventList);
        })
        .catch((err) => {
          console.log('Error getting documents', err);
        });
    },
    getFighterList({ commit }, payload) {
      commit('setLoading', true);
      let today = new Date().getTime();
      // console.log(today)
      let query = db
        .collection('fighters')
        .where('event_id', '==', payload.event_doc_id);
      // console.log(query)
      query
        .get()
        .then((snapshot) => {
          if (snapshot.empty) {
            console.log('No matching Fighters.');
            commit('setFighterListEmpty', true);
            return;
          }
          // let index = 0
          console.log('payload.current_arr');
          console.log(payload.current_arr);
          var fighterList = [];
          if (payload.loadMore) {
            fighterList = payload.current_arr;
          }
          snapshot.forEach((doc) => {
            // console.log(doc.id, '=>', doc.data())
            var obj = doc.data();
            obj.doc_id = doc.id;
            obj.checkTime = Math.round(today / 1000 + 1800); // 1 Hour to prevent to much Firebase loading/reading
            fighterList.push(obj);
            // var inArray = eventList.findIndex(item => item.id === obj.id) > -1
          });
          // console.log(fighterList)
          commit('setFighterListEmpty', false);
          commit('setFighterList', fighterList);
        })
        .catch((err) => {
          console.log('Error getting documents', err);
        });
    },
    // ############ Get User Predictions ########## Snapshot Listener -->
    getUserPredictions({ commit }, payload) {
      commit('setLoading', true);
      let today = new Date().getTime();
      // console.log(today)
      let query = db
        .collection('predictions')
        .where('event_doc_id', '==', payload.event_doc_id)
        .where('uid', '==', payload.uid);
      // console.log(query)
      query.onSnapshot(
        (querySnapshot) => {
          if (querySnapshot.empty) {
            console.log('No matching Predictions.');
            commit('setUserPredictionsEmpty', true);
            return;
          }
          console.log('payload.current_arr');
          console.log(payload.current_arr);
          var predictionsList = [];
          querySnapshot.forEach((doc) => {
            // console.log(doc.id, '=>', doc.data());
            var obj = doc.data();
            obj.doc_id = doc.id;
            obj.checkTime = Math.round(today / 1000 + 1800); // 1 Hour to prevent to much Firebase loading/reading
            predictionsList.push(obj);
            // var inArray = eventList.findIndex(item => item.id === obj.id) > -1
          });
          console.log('##### Predictions List loaded ######');
          console.log(predictionsList);
          commit('setUserPredictionsEmpty', false);
          commit('setUserPredictionsArr', predictionsList);
        },
        (err) => {
          console.log(`Encountered error: ${err}`);
        }
      );
    },
    // ############### Get All Time Leadeer Board ###############
    getLiveLeaderboard({ commit }, payload) {
      commit('setLoading', payload.limit);
      let query = db
        .collection('leaderboard_live')
        .doc('event_' + payload.eventId);
      query
        .get()
        .then((snapshot) => {
          if (snapshot.empty) {
            console.log('No matching Leaderboard Documents.');
            return;
          }
          console.log('snapshot');
          console.log(snapshot.data());
          var snapshotLive = snapshot.data();
          var liveLeaderBoardList = []; // payload.current_arr
          if (payload.loadMore) {
            liveLeaderBoardList = payload.current_arr;
          }
          // var count = 0 // payload.current_arr.length
          for (var s in snapshotLive) {
            // console.log(doc.id, '=>', doc.data())
            if (s !== 'modified') {
              var obj = {}
              obj = snapshotLive[s];
              console.log(snapshotLive[s])
              obj.doc_id = snapshotLive[s].id;
              var inArray =
                liveLeaderBoardList.findIndex((item) => item.uid === obj.uid) >
                -1;
              if (!inArray) {
                //
                liveLeaderBoardList.push(obj);
              }
            }
            // var inArray = eventList.findIndex(item => item.id === obj.id) > -1
          }
          liveLeaderBoardList.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.total_points) - new Date(a.total_points);
          });
          var previousScore = 0
          var previousRank = 0
          for (var i in liveLeaderBoardList) {
            if (liveLeaderBoardList[i].total_points === previousScore) {
              liveLeaderBoardList[i].rank = previousRank
            } else {
              liveLeaderBoardList[i].rank = parseInt(previousRank) + 1;
            }
            previousScore = liveLeaderBoardList[i].total_points
            previousRank = liveLeaderBoardList[i].rank 
          }
          console.log(liveLeaderBoardList);
          commit('setLiveLeaderboard', liveLeaderBoardList);
        })
        .catch((err) => {
          console.log('Error getting documents', err);
        });
    },
    // ############### Get All Time Leader Board ###############
    getAllTimeLeaderboard({ commit }, payload) {
      commit('setLoading', payload.limit);
      let today = new Date().getTime();
      let lastMonth = today - 1000 * 3600 * 24 * 30;
      // console.log(today)
      let query = db
        .collection('leaderboard')
        .orderBy('total_points', 'desc')
        .limit(payload.limit);
      query
        .get()
        .then((snapshot) => {
          if (snapshot.empty) {
            console.log('No matching Leaderboard Documents.');
            return;
          }
          console.log('payload.current_arr');
          console.log(payload.current_arr);
          var leaderBoardList = []; // payload.current_arr
          var leaderBoardRewardList = []
          if (payload.loadMore) {
            leaderBoardList = payload.current_arr;
          }
          var count = 0; // payload.current_arr.length
          var previousScore = 0
          var previousRank = 0
          var count2 = 0; // payload.current_arr.length
          var previousScore2 = 0
          var previousRank2 = 0
          snapshot.forEach((doc) => {
            // console.log(doc.id, '=>', doc.data())
            var obj = doc.data();
            var obj2 = doc.data();
            obj.doc_id = doc.id;
            obj2.doc_id = doc.id;
            // Fun
            if (obj.total_points === previousScore) {
              obj.rank = previousRank
            } else {
              count += 1;
              obj.rank = count;
            }
          
            obj.checkTime = Math.round(today / 1000 + 1800); // 30 min to prevent to much Firebase loading/reading
            var inArray = leaderBoardList.findIndex((item) => item.uid === obj.uid) > -1;
            // console.log('obj.modified ' + obj.username);
            // console.log(obj.modified);
            // console.log('lastMonth');
            // console.log(lastMonth);
            if (!inArray && obj.modified > lastMonth) {
              //
              leaderBoardList.push(obj);
              if (obj2.mola_subscription === 1 && obj2.email.split('@')[1] !== 'mola.tv') {
                // Reward
                if (obj2.total_points === previousScore2) {
                  obj2.rank = previousRank2
                  previousScore2 = obj2.total_points
                  previousRank2 = obj2.rank
                } else {
                  count2 += 1;
                  obj2.rank = count2;
                  previousScore2 = obj2.total_points
                  previousRank2 = obj2.rank
                }
                leaderBoardRewardList.push(obj2);
              }
              previousScore = obj.total_points
              previousRank = obj.rank
            }
          });
          console.log(leaderBoardList);
          commit('setAllTimeLeaderboard', leaderBoardList);
          commit('setAllTimeRewardLeaderboard', leaderBoardRewardList);
        })
        .catch((err) => {
          console.log('Error getting documents', err);
        });
    },
    // ################# Get Weekly Leaderboard ##############################
    getWeeklyLeaderboard({ commit }, payload) {
      commit('setLoading', payload.limit)
      let leaderboardEventIds = []
      let today = new Date().getTime()
      var leaderBoardWeeklyList = []
      var leaderBoardRewardWeeklyList = []
      var eventArr = payload.eventArr
      let query = ''
      // let query = db.collection('leaderboard_live').orderBy('modified', 'desc').limit(2)
      console.log('########## eventArr ############');
      console.log(eventArr);
      if (eventArr.length === 0) {
        query = db
          .collection('leaderboard_live')
          .orderBy('modified', 'desc')
          .limit(1);
      } else {
        query = db
          .collection('leaderboard_live')
          .orderBy('modified', 'desc')
          .limit(eventArr.length);
      }
      query.get().then((snapshotLive) => {
        console.log('X', snapshotLive);
        snapshotLive.forEach((doc) => {
          console.log('############# leaderboard_live ###############');
          console.log(doc.id, '=>', doc.data());
          leaderboardEventIds.push(doc.id);
          var snapshotWeekly = doc.data();
          console.log("Snapshot", snapshotWeekly);
          if (payload.loadMore) {
            leaderBoardWeeklyList = payload.current_arr;
          }
          // var count = 0 // payload.current_arr.length
          for (var s in snapshotWeekly) {
            if (s !== 'modified') {
              var obj = snapshotWeekly[s];
              obj.doc_id = snapshotWeekly[s].id;
              obj.checkTime = Math.round(today / 1000 + 1800); // 1 Hour to prevent to much Firebase loading/reading
              var findIndex = leaderBoardWeeklyList.findIndex((item) => item.uid === obj.uid);
              console.log(
                'Lead Fun',
                obj.doc_id,
                obj.username,
                obj.total_points
              );
              if (findIndex === -1) {
                // Not in Arr yet so push 1st obj to the leaderBoardWeeklyList
                console.log(
                  'Fun Calc Init',
                  obj.uid, obj.username,
                  obj.total_points
                );
                leaderBoardWeeklyList.push(obj);
              } else {
                console.log(
                  'Fun Calc Before',
                  leaderBoardWeeklyList[findIndex].uid,
                  leaderBoardWeeklyList[findIndex].username,
                  leaderBoardWeeklyList[findIndex].total_points
                );
                console.log(
                  'Fun Calc Add',
                  obj.uid,
                  obj.username,
                  obj.total_points
                );
                // Try mix and merge 2 live leader boards and add up the totals
                leaderBoardWeeklyList[findIndex].total_points += obj.total_points;
                leaderBoardWeeklyList[findIndex].correct_outcome += obj.correct_outcome;
                leaderBoardWeeklyList[findIndex].correct_method += obj.correct_method;
                leaderBoardWeeklyList[findIndex].correct_rounds += obj.correct_rounds;
                leaderBoardWeeklyList[findIndex].event_doc_id.push( obj.doc_id.replace('event_', ''));
                console.log(
                  'Fun Calc Total',
                  leaderBoardWeeklyList[findIndex].uid,
                  leaderBoardWeeklyList[findIndex].username,
                  leaderBoardWeeklyList[findIndex].total_points
                );
              }
            }
            // var inArray = eventList.findIndex(item => item.id === obj.id) > -1
          }

          for (var ss in snapshotWeekly) {
            if (ss !== 'modified') {
              var obj2 = snapshotWeekly[ss];
              obj2.doc_id = snapshotWeekly[ss].id;
              obj2.checkTime = Math.round(today / 1000 + 1800); // 1 Hour to prevent to much Firebase loading/reading
              var findIndex2 = leaderBoardRewardWeeklyList.findIndex((item) => item.uid === obj2.uid);
              console.log(
                'Lead Reward',
                obj2.doc_id,
                obj2.username,
                obj2.total_points
              );
              if (findIndex2 === -1) {
                // Not in Arr yet so push 1st obj to the leaderBoardWeeklyList
                if (obj2.mola_subscription === 1 && obj2.email.split('@')[1] !== 'mola.tv') {
                  leaderBoardRewardWeeklyList.push(obj2);
                }
              } else {
                if (obj2.mola_subscription === 1 && obj2.email.split('@')[1] !== 'mola.tv') {
                  leaderBoardRewardWeeklyList[findIndex2].total_points += obj2.total_points;
                  leaderBoardRewardWeeklyList[findIndex2].correct_outcome += obj2.correct_outcome;
                  leaderBoardRewardWeeklyList[findIndex2].correct_method += obj2.correct_method;
                  leaderBoardRewardWeeklyList[findIndex2].correct_rounds += obj2.correct_rounds;
                  leaderBoardRewardWeeklyList[findIndex2].event_doc_id.push(obj2.doc_id.replace('event_', ''));
                }
              }
            }
            // var inArray = eventList.findIndex(item => item.id === obj.id) > -1
          }
        });
        leaderBoardWeeklyList.sort(function (a, b) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(b.total_points) - new Date(a.total_points);
        });
        leaderBoardRewardWeeklyList.sort(function (a, b) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(b.total_points) - new Date(a.total_points);
        });
        // Fun
        var count = 0;
        var previousScore = 0
        var previousRank = 0
        for (var i in leaderBoardWeeklyList) {
          if (leaderBoardWeeklyList[i].total_points === previousScore) {
            leaderBoardWeeklyList[i].rank = previousRank
            previousScore = leaderBoardWeeklyList[i].total_points
            previousRank = leaderBoardWeeklyList[i].rank 
          } else {
            count += 1
            leaderBoardWeeklyList[i].rank = count
            previousScore = leaderBoardWeeklyList[i].total_points
            previousRank = leaderBoardWeeklyList[i].rank 
          }
        }
        // Reward
        var count2 = 0;
        var previousScore2 = 0
        var previousRank2 = 0
        for (var ir in leaderBoardRewardWeeklyList) {
          if (leaderBoardRewardWeeklyList[ir].total_points === previousScore2) {
            leaderBoardRewardWeeklyList[ir].rank = previousRank2
            previousScore2 = leaderBoardRewardWeeklyList[ir].total_points
            previousRank2 = leaderBoardRewardWeeklyList[ir].rank 
          } else {
            count2 += 1
            leaderBoardRewardWeeklyList[ir].rank = count2
            previousScore2 = leaderBoardRewardWeeklyList[ir].total_points
            previousRank2 = leaderBoardRewardWeeklyList[ir].rank 
          }
        }
        console.log('############# leaderBoardWeeklyList ##############');
        console.log(leaderBoardWeeklyList);
        commit('setWeeklyLeaderboard', leaderBoardWeeklyList);
        commit('setWeeklyRewardLeaderboard', leaderBoardRewardWeeklyList);
        commit('setWeeklyLeaderboardIds', leaderboardEventIds);
      });
    },
    // ################# Insert Fighter from API to firabase #################
    insertFighter({ commit }, payload) {
      commit('setLoading', true);
      let postkey = db.collection('fighters').doc();
      db.collection('fighters')
        .doc(postkey.id)
        .set(payload)
        .then(() => {
          console.log('Fighter in bucket created');
        })
        .catch((error) => {
          console.log(error);
        });
    },
    insertPrediction({ commit }, payload) {
      commit('setLoading', true);
      db.collection('predictions')
        .doc(payload.id)
        .set(payload)
        .then(() => {
          console.log('Prediction in bucket created');
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updatePrediction({ commit }, payload) {
      commit('setLoading', true);
      console.log(payload);
      db.collection('predictions')
        .doc(payload.id)
        .update(payload)
        .then(() => {
          console.log('Prediction in bucket updated!');
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateEventPredictionPercent({ commit }, payload) {
      // console.log(payload)
      commit('setLoading', true);
      const prediction = {
        home: payload.home,
        away: payload.away,
      };
      console.log(prediction);
      db.collection('events')
        .doc(payload.postkeyEvent)
        .collection('fights')
        .doc(payload.postkeyFight)
        .update(prediction)
        .then(() => {
          console.log('Fight Prediction Count in bucket updated');
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // ##################################################################################
    // ##################### Event Fight Result Processing Operation ####################
    // ##################################################################################
    updateOperationEventFight({ commit }, payload) {
      commit('setLoading', true);
      const fightObj = {
        result_outcome: payload.fight.result_outcome.id,
        result_method: payload.fight.result_method.id,
        result_rounds: payload.fight.result_rounds.id,
        results_processed: new Date().getTime(),
      };
      db.collection('events')
        .doc(payload.postkeyEvent)
        .collection('fights')
        .doc(payload.postkeyFight)
        .update(fightObj)
        .then(() => {
          console.log('Event Fight in bucket updated');
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateEventResultsProcessed({ commit }, payload) {
      commit('setLoading', true);
      const eventObj = {
        results_processed: new Date().getTime()
        // status: 2
      };
      db.collection('events')
        .doc(payload.postkeyEvent)
        .update(eventObj)
        .then(() => {
          console.log('Event Results Processed in bucket updated');
        })
        .catch((error) => {
          console.log(error);
        });
    }, 
    // ##################################################################################
    // ##################### User Predictions Processing Operation ######################
    // ##################################################################################
    updateOperationUserPredictions({ commit }, payload) {
      commit('setLoading', true);
      const predictionObj = {
        points_won_outcome: payload.points_won_outcome ?? 0,
        points_won_method: payload.points_won_method ?? 0,
        points_won_rounds: payload.points_won_rounds ?? 0,
        points_won: payload.points_won ?? 0,
        results_processed: new Date().getTime(),
      };
      console.log('predictionObj');
      console.log(predictionObj);
      db.collection('predictions')
        .doc(payload.id)
        .set(predictionObj, { merge: true })
        .then(() => {
          console.log('Prediction in bucket updated');
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // ##################################################################################
    // ##################### Insert Points Ledger Operation #############################
    // ##################################################################################
    updateOperationInsertPoints({ commit }, payload) {
      commit('setLoading', true);
      var tx_id =
        Math.random().toString(36).substring(2, 12) +
        Math.random().toString(36).substring(2, 12) +
        Math.random().toString(36).substring(2, 12);
      const pointsObj = {
        tx_id: '0x' + tx_id,
        uid: payload.uid,
        points: payload.points_won,
        type: 1, // 1: prediction game credit, 2:redeem points product debit, 3:swap to token debit, 4:refund)
        type_text: 'Prediction Game Credit',
        fid: payload.fight_doc_id,
        eid: payload.event_doc_id,
        transaction_timestamp: new Date().getTime(),
        status: 1, // 1:completed, 2:pending, 3:cancelled)
      };
      console.log(pointsObj);
      let postkey = db.collection('points').doc();
      db.collection('points')
        .doc(postkey.id)
        .set(pointsObj)
        .then(() => {
          console.log('Points in ledger created');
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // ##################################################################################
    // ##################### Insert / Update Leaderboard Operation ######################
    // ##################################################################################
    updateOperationLeaderboard({ commit }, payload) {
      commit('setLoading', true);
      db.collection('leaderboard')
        .doc(payload.uid)
        .set(payload, { merge: true })
        .then(() => {
          console.log('Leaderboard updated');
          // Update user account mola_points !! >> Move to seperate function
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateOperationUserPoints({ commit }, payload) {
      commit('setLoading', true);
      db.collection('users')
        .doc(payload.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            console.log('User Document data:', doc.data());
            let currentPoints = doc.data().mola_points ?? 0;
            let totalPointsWon = doc.data().total_points_won ?? 0;
            let weeklyPointsWon = doc.data().weekly_points_won ?? 0;
            let updatePoints = {
              mola_points: currentPoints + payload.last_points_won,
              total_points_won: totalPointsWon + payload.last_points_won,
              weekly_points_won: weeklyPointsWon + payload.last_points_won,
              mola_points_updated: new Date().getTime() - 1000 * 60 * 60 * 24,
            };
            db.collection('users')
              .doc(payload.uid)
              .update(updatePoints)
              .then(() => {
                console.log('User Mola Points in bucket updated');
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            // doc.data() will be undefined in this case
            console.log('No such document!');
          }
        })
        .catch((error) => {
          console.log('Error getting document:', error);
        });
    },
    // ##################################################################################
    // ##################### Insert / Update Live Leaderboard Operation ###############
    // ##################################################################################
    updateOperationLeaderboardLive({ commit }, payload) {
      commit('setLoading', true);
      const weekObj = {};
      weekObj.modified = new Date().getTime();
      weekObj[payload.uid] = payload;
      db.collection('leaderboard_live')
        .doc(payload.id)
        .set(weekObj, { merge: true })
        .then(() => {
          console.log('Weekly / Live Leaderboard updated');
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // ##################################################################################
    // ##################### Get Leaderboard Detail from Predictions ####################
    // ##################################################################################
    getDetailOfLeaderboard({ commit }, payload) {
      // const today = new Date().getTime();
      // const lastMonth = today - 1000 * 3600 * 24 * 30;
      const arrEvents = payload.events;
      const uid = payload.uid;
      console.log(payload);
      let query;
      if (payload.monthly) {
        query = db
          .collection('predictions')
          .where('uid', '==', uid)
          .orderBy('created', 'asc');
      } else {
        query = db
          .collection('predictions')
          .where('event_doc_id', 'in', arrEvents)
          .where('uid', '==', uid)
          .orderBy('created', 'asc');
      }
      
      const detailArr = [];
      query
        .get()
        .then((snapshot) => {
          console.log(snapshot);
          if (snapshot.empty) {
            return;
          }
          snapshot.forEach((doc) => {
            // if(payload.monthly){
            //   if (doc.data().modified > lastMonth) {
            //     detailArr.push(doc.data());
            //   }
            // }
            // else{
            //   detailArr.push(doc.data());
            // }
            detailArr.push(doc.data());
          });
          commit('setLeaderboardDetail', detailArr);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // next function
  },
  getters: {
    eventList(state) {
      return state.eventList;
    },
    eventListEmpty(state) {
      return state.eventListEmpty;
    },
    fighterList(state) {
      return state.fighterList;
    },
    fighterListEmpty(state) {
      return state.fighterListEmpty;
    },
    fightTypesArr(state) {
      return state.fightTypesArr;
    },
    userPredictionsArr(state) {
      return state.userPredictionsArr;
    },
    userPredictionsEmpty(state) {
      return state.userPredictionsEmpty;
    },
    allTimeRewardLeaderboard(state) {
      return state.allTimeRewardLeaderboard;
    },
    allTimeLeaderboard(state) {
      return state.allTimeLeaderboard;
    },
    allTimeLBEmpty(state) {
      return state.allTimeLBEmpty;
    },
    weeklyRewardLeaderboard(state) {
      return state.weeklyRewardLeaderboard;
    },
    weeklyLeaderboard(state) {
      return state.weeklyLeaderboard;
    },
    weeklyLBEmpty(state) {
      return state.weeklyLBEmpty;
    },
    liveLeaderboard(state) {
      return state.liveLeaderboard;
    },
  },
};

export default FirebaseModule
