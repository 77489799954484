import Vue from "vue";
import Vuex from "vuex";
import FirebaseModule from './FirebaseModule'
import user from './user'
import point from './point';
import admin from './admin';
import shared from './shared'
import catalog from './catalog';
import cart from './cart';
import checkout from './checkout';
import order from './order';
// import { FIGHTERS } from "./known_fighter_list";
import { LANG } from '../locale/language'
import MolaMfpAPI from "../clients/MolaMfpAPI";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      accType: 0,
      firebaseUserId:
        localStorage.getItem('firebaseId') === null
          ? ''
          : localStorage.getItem('firebaseId'),
      email:
        localStorage.getItem('email') === null
          ? ''
          : localStorage.getItem('email'),
      emailLink:
        localStorage.getItem('emailLink') === null
          ? ''
          : localStorage.getItem('emailLink'),
    },
    reloadCount: 0,
    liveLeaderBoardEventId: '',
    fighters: [],
    lang: LANG,
    current_language: 
      localStorage.getItem('current_language') === null
        ? 'id'
        : localStorage.getItem('current_language'),
    isReload: false,
    sessionId: localStorage.getItem('mola-mfp-session-id') === null
      ? ''
      : localStorage.getItem('mola-mfp-session-id'),
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setLiveLeaderbordEventId(state, payload) {
      console.log(payload);
      state.liveLeaderBoardEventId = payload;
    },
    setReloadCounter(state, payload) {
      console.log(payload);
      state.reloadCount = state.reloadCount += 1;
    },
    SetTheme(state, payload) {
      state.user.dark = payload.dark;
      localStorage.setItem('mola-catalog-darkTheme', payload.dark);
    },
    SetCustomFighters(state, payload) {
      state.fighters = payload;
    },
    SetUser(state, payload) {
      const keys = ['firebaseId', 'emailLink', 'email', 'isLoggedIn'];
      const values = [
        payload.firebaseUserId,
        payload.emailLink,
        payload.email,
        payload.isLoggedIn,
      ];
      state.user.firebaseUserId = payload.firebaseUserId;
      state.user.email = payload.email;
      state.user.userName = payload.userName;
      state.user.isLoggedIn = payload.isLoggedIn;
      state.user.accType = payload.accType;
      state.user.emailLink = payload.emailLink;
      keys.forEach((key, index) => {
        console.log(index);
        localStorage.setItem(key, values[index]);
      });
    },
    SetDefaultUser(state, payload) {
      const keys = ['firebaseId', 'emailLink', 'email', 'isLoggedIn'];
      state.user.firebaseUserId = payload;
      state.user.email = payload;
      state.user.userName = payload;
      state.user.isLoggedIn = payload;
      state.user.accType = payload;
      state.user.emailLink = payload;
      keys.forEach((key) => {
        localStorage.removeItem(key);
      });
    },
    SetCurrentLanguage(state, payload) {
      state.current_language = payload;
      localStorage.setItem('current_language', payload);
    },
    setIsReload(state, payload){
      state.isReload = payload.isReload
    },
    setDeviceId(state, payload) {
      localStorage.setItem('mola-mfp-session-id', payload);
      state.sessionId = payload
    }
  },
  actions: {
    async checkSession({commit, state, dispatch}) {
      const resp = await MolaMfpAPI.getSession(state.sessionId ?? '')
      console.log('session', resp.data.data)
      if (!resp.data.data.isLoggedIn) {
        commit('setDeviceId', '')
        localStorage.removeItem('mola-mfp-session-id')
        dispatch('logout')
      }
    }
  },
  modules: {
    fb: FirebaseModule,
    user: user,
    shared: shared,
    point: point,
    admin: admin,
    catalog: catalog,
    cart: cart,
    checkout: checkout,
    order: order,
  },
});
