import MolaMfpAPI from '../clients/MolaMfpAPI';
import { db, dbId, dbUK } from '../main';
import firebase from 'firebase/app';

export default {
  state: {
    itemCatalog: [],
    lastVisible: null,
    lastVisibleFrom: '',
    categories: [],
    isLoading: false,
    digitalCount: 0,
    physicalCount: 0,
    newItem: [],
    popularItem: [],
    productDetail: null,
    tempItemCatalog: [],
    filter: '',
    search: '',
    message: '',
    filterMin: null,
    filterMax: null,
    filterCategory: '',
    orderBy: 'title',
    orderState: 'asc',
    sortName: '',
    sortFilter: 0,
    item_type: [0,1],
    region: localStorage.getItem('mola-catalog-region') === null
      ? 'AS'
      : localStorage.getItem('mola-catalog-region'),
  },
  mutations: {
    setState(state, payload) {
      var keys = Object.keys(payload);
      keys.forEach((key) => {
        state[key] = payload[key];
      });
    },
    setSearch (state, payload) {
      state.search = payload
    },
    setFilterCategory (state, payload) {
      state.filterCategory = payload
    },
    setFilterMin (state, payload) {
      state.filterMin = payload
    },
    setFilterMax (state, payload) {
      state.filterMax = payload
    },
    setOrderBy (state, payload) {
      state.orderBy = payload
    },
    setOrderState (state, payload) {
      state.orderState = payload
    }
  },
  actions: {
    async getCatalogs({ commit, state }, payload) {
      console.log('getCatalogs', state.itemCatalog)
      if(!localStorage.getItem('fetching-region')) {
        const resp = await MolaMfpAPI.getRegion();
        state.region = resp.data?.continentCode ?? 'AS'
      }
      
      commit('setState', {
        isLoading: true,
      });

      // let region;
      // if ([null, undefined, ''].includes(localStorage.getItem('mola-catalog-region'))) {
      //   const resp = await MolaMfpAPI.getRegion();
      //   region = resp.data?.continentCode ?? 'AS'
      // } else {
      //   region = state.region ?? localStorage.getItem('mola-catalog-region');
      // }


      let useDB = db
      if (state.region === 'EU') {
        useDB = dbUK
      }

      console.log(payload);
      let arrProducts = [];
      let lastVisible = payload.lastVisible ?? null;
      let lastVisibleFrom = payload.lastVisibleFrom ?? '';
      const limit = payload.limit ?? 100;
      const filter = payload.filter ?? '';
      const search = payload.search ?? '';

      commit('setSearch', search);
      commit('setState', {sortFilter: payload.sortFilter});
      if (payload.reload) {
        commit('setOrderBy', 'item_price_mola_points');
      }
      if (payload === {} || payload.reload) {
        commit('setFilterCategory', '');
        commit('setFilterMin', null);
        commit('setFilterMax', null);
      } else {
        commit('setFilterCategory', filter);
      }

      console.log('Default Catalog filter applied.')
      console.log('state.orderBy')
      console.log(state.orderBy)
      if (state.orderBy !== 'publish') {
        commit('setOrderBy', 'item_price_mola_points');
      } 

      let query = useDB
        .collection('catalog')
        .where('status', '==', 2)
        .orderBy('importance', 'asc')
        .orderBy(state.orderBy, state.orderState)
        .limit(limit);
      
        console.log('########## state.orderBy publish ###################')
        console.log(state.orderBy)
        console.log(state.orderState)

      if (filter !== '' && state.orderBy !== 'publish') {

        console.log('Min filter applied Q1')
        if (![undefined,null, ''].includes(state.filterMin) && ![undefined,null, ''].includes(state.filterMax)) {
          console.log('Min filter applied Q1.1')

          arrProducts = [];
          query = useDB
            .collection('catalog')
            .where('status', '==', 2)
            .where('category', '==', filter)
            .where('item_price_mola_points', '>=', parseInt(state.filterMin))
            .where('item_price_mola_points', '<=', parseInt(state.filterMax))
            .orderBy(state.orderBy, state.orderState)
            .limit(limit);
          const key = `category-${
            Array.isArray(filter) ? filter.join('-') : filter
          }`;
          if (lastVisibleFrom !== key) {
            lastVisible = null;
            commit('setState', {
              lastVisibleFrom: key,
            });
          }
  
        } else if (![undefined,null, ''].includes(state.filterMin)) {
          console.log('Min filter applied Q1.2')
          console.log(state.filterMin)
          arrProducts = [];
          query = useDB
            .collection('catalog')
            .where('status', '==', 2)
            .where('category', '==', filter)
            .where('item_price_mola_points', '>=', parseInt(state.filterMin))
            .orderBy(state.orderBy, state.orderState)
            .limit(limit);
          const key = `category-${
            Array.isArray(filter) ? filter.join('-') : filter
          }`;
          if (lastVisibleFrom !== key) {
            lastVisible = null;
            commit('setState', {
              lastVisibleFrom: key,
            });
          }
  
        } else if (![undefined,null, ''].includes(state.filterMax)) {
          console.log('Min filter applied Q1.3')

          arrProducts = [];
          query = useDB
            .collection('catalog')
            .where('status', '==', 2)
            .where('category', '==', filter)
            .where('item_price_mola_points', '<=', parseInt(state.filterMax))
            .orderBy(state.orderBy, state.orderState)
            .limit(limit);
          const key = `category-${
            Array.isArray(filter) ? filter.join('-') : filter
          }`;
          if (lastVisibleFrom !== key) {
            lastVisible = null;
            commit('setState', {
              lastVisibleFrom: key,
            });
          }
  
        } else {

          console.log('Filter applied Q2.1')
          arrProducts = [];
          // var item_type = state.item_type
          console.log(state.item_type)
          query = useDB
            .collection('catalog')
            .where('status', '==', 2)
            .where('category', '==', filter)
            // .where('item_type', 'in', item_type)
            .orderBy(state.orderBy, state.orderState)
            .limit(limit);
          const key = `category-${
            Array.isArray(filter) ? filter.join('-') : filter
          }`;
          if (lastVisibleFrom !== key) {
            lastVisible = null;
            commit('setState', {
              lastVisibleFrom: key,
            });
          }
        } 

      } else if (filter === '' && ![undefined,null, ''].includes(state.filterMin) && ![undefined,null, ''].includes(state.filterMax)) {
        
        console.log('Min filte category unselect  applied Q2.2')
        arrProducts = [];
          query = useDB
            .collection('catalog')
            .where('status', '==', 2)
            .where('item_price_mola_points', '>=', parseInt(state.filterMin))
            .where('item_price_mola_points', '<=', parseInt(state.filterMax))
            .orderBy(state.orderBy, state.orderState)
            .limit(limit);
          const key = `category-${
            Array.isArray(filter) ? filter.join('-') : filter
          }`;
          if (lastVisibleFrom !== key) {
            lastVisible = null;
            commit('setState', {
              lastVisibleFrom: key,
            });
          }
      } else if (filter === '' && ![undefined,null, ''].includes(state.filterMin)) {
        
        arrProducts = [];
          query = useDB
            .collection('catalog')
            .where('status', '==', 2)
            .where('item_price_mola_points', '>=', parseInt(state.filterMin))
            .orderBy(state.orderBy, state.orderState)
            .limit(limit);
          const key = `category-${
            Array.isArray(filter) ? filter.join('-') : filter
          }`;
          if (lastVisibleFrom !== key) {
            lastVisible = null;
            commit('setState', {
              lastVisibleFrom: key,
            });
          }
      } else if (filter === '' && ![undefined,null, ''].includes(state.filterMax)) {
        
          arrProducts = [];
          query = useDB
            .collection('catalog')
            .where('status', '==', 2)
            .where('item_price_mola_points', '<=', parseInt(state.filterMax))
            .orderBy(state.orderBy, state.orderState)
            .limit(limit);
          const key = `category-${
            Array.isArray(filter) ? filter.join('-') : filter
          }`;
          if (lastVisibleFrom !== key) {
            lastVisible = null;
            commit('setState', {
              lastVisibleFrom: key,
            });
          }
      } else if (filter !== '' && state.orderBy === 'publish') {
        console.log('Publish filter applied')

        arrProducts = [];
        query = useDB
          .collection('catalog')
          .where('status', '==', 2)
          .where('category', '==', filter)
          .orderBy(state.orderBy, state.orderState)
          .limit(limit);
        const key = `category-${
          Array.isArray(filter) ? filter.join('-') : filter
        }`;
        if (lastVisibleFrom !== key) {
          lastVisible = null;
          commit('setState', {
            lastVisibleFrom: key,
          });
        }

      } else if (filter === '' && state.orderBy === 'publish') {
        console.log('Points low to high or vs - getting')
        arrProducts = [];
        query = useDB
          .collection('catalog')
          .where('status', '==', 2)
          .orderBy(state.orderBy, state.orderState)
          .limit(limit);
        const key = `category-${
          Array.isArray(filter) ? filter.join('-') : filter
        }`;
        if (lastVisibleFrom !== key) {
          lastVisible = null;
          commit('setState', {
            lastVisibleFrom: key,
          });
        }
      } else if (filter === '' && state.orderBy !== 'publish' && state.sortFilter === 1 ) {
        console.log('Points low to high or vs - getting')
        arrProducts = [];
        query = useDB
          .collection('catalog')
          .where('status', '==', 2)
          .orderBy(state.orderBy, state.orderState)
          .limit(limit);
        const key = `category-${
          Array.isArray(filter) ? filter.join('-') : filter
        }`;
        if (lastVisibleFrom !== key) {
          lastVisible = null;
          commit('setState', {
            lastVisibleFrom: key,
          });
        }
      } else {
        console.log('No query applied')
        commit('setState', {
          filter: '',
        });
      }
      // Expanded Search
      if (search !== '') {

        if (state.orderBy !== 'publish') {

          if (![undefined,null, ''].includes(state.filterMin) && ![undefined,null, ''].includes(state.filterMax)) {

            console.log('search Query 1')
            arrProducts = [];
            let searchFilter = search.split(' ');
            console.log(state.orderBy);
            console.log(state.orderState);
            query = useDB
              .collection('catalog')
              .where('status', '==', 2)
              .where('item_price_mola_points', '>=', parseInt(state.filterMin))
              .where('item_price_mola_points', '<=', parseInt(state.filterMax))
              .where('keywords', 'array-contains-any', searchFilter)
              .orderBy(state.orderBy, state.orderState)
            lastVisible = null;

          } else if (filter !== '') {

            console.log('search Query 1.1')
            arrProducts = [];
            let searchFilter = search.split(' ');
            console.log(state.orderBy);
            console.log(state.orderState);
            query = useDB
              .collection('catalog')
              .where('status', '==', 2)
              .where('category', '==', filter)
              .where('keywords', 'array-contains-any', searchFilter)
              .orderBy(state.orderBy, state.orderState)
            lastVisible = null;

          } else {

            console.log('search Query 1.2')
            arrProducts = [];
            let searchFilter = search.split(' ');
            console.log(searchFilter);
            console.log(typeof searchFilter);
            query = useDB
              .collection('catalog')
              .where('status', '==', 2)
              .where('keywords', 'array-contains-any', searchFilter)
              .orderBy(state.orderBy, state.orderState)
            lastVisible = null;

          }

        } else {
         
          if (![undefined,null, ''].includes(state.filterMin) && ![undefined,null, ''].includes(state.filterMax)) {

            console.log('search Query 2')
            arrProducts = [];
            let searchFilter = search.split(' ');
            console.log(searchFilter);
            console.log(typeof searchFilter);
            query = useDB
              .collection('catalog')
              .where('status', '==', 2)
              .where('item_price_mola_points', '>=', parseInt(state.filterMin))
              .where('item_price_mola_points', '<=', parseInt(state.filterMax))
              .where('keywords', 'array-contains-any', searchFilter)
              .orderBy(state.orderBy, state.orderState)
            lastVisible = null;

          } else {

            console.log('search Query 2.1')
            arrProducts = [];
            let searchFilter = search.split(' ');
            console.log(searchFilter);
            console.log(typeof searchFilter);
            query = useDB
              .collection('catalog')
              .where('status', '==', 2)
              .where('keywords', 'array-contains-any', searchFilter)
              .orderBy(state.orderBy, state.orderState)
            lastVisible = null;

          }
        }

      }
      // Expanded Search END
      if (lastVisible !== null) {
        query.startAfter(lastVisible);
      }
      try {
        if (arrProducts.length === 0 || payload.next === true) {
          console.log(query);
          const snapshot = await query.get();
          console.log(snapshot.size);
          snapshot.forEach((doc) => {
            const obj = doc.data();
            obj.id = doc.id;
            // console.log(obj.title);
            arrProducts.push(obj);
            
            // add slug temp
            /* var slug = obj.title //.toLowerCase();
            slug = slug.replaceAll(' ', '-');
            slug = slug.replaceAll('/', '');
            slug = slug.replaceAll('?', '');
            db.collection('catalog').doc(doc.id).update(
              {
                slug: slug.toLowerCase()
              }
            ); */
          });
          lastVisible = snapshot.docs[snapshot.docs.length - 1];
          const digitalProductCount = arrProducts.filter(product => product.item_type === 0).length;
          const physicalProductCount = arrProducts.filter(product => product.item_type === 1).length;

          console.log('state.item_type')
          console.log(state.item_type)
          commit('setState', {
            tempItemCatalog: arrProducts,
            itemCatalog: arrProducts
          })
          if (state.filterCategory === 'Voucher' && state.item_type.findIndex(item => item === 0 && state.item_type.length === 1) > -1) {
            console.log('Condition 1 is met');
            arrProducts = state.tempItemCatalog.filter(product => product.item_type === 0)
          } else if (state.filterCategory === 'Voucher' && state.item_type.findIndex(item => item === 1 && state.item_type.length === 1) > -1) {
            console.log('Condition 2 is met');
            console.log(state.item_type);
            arrProducts = state.tempItemCatalog.filter(product => product.item_type === 1)
          } else if (state.filterCategory === '' && state.item_type.findIndex(item => item === 1) > -1 && state.item_type.length === 1) {
            console.log('Condition 3 is met');
            arrProducts = state.tempItemCatalog.filter(product => product.item_type === 1)
          } else if (state.filterCategory === '' && state.item_type.findIndex(item => item === 0) > -1 && state.item_type.length === 1) {
            console.log('Condition 4 is met');
            arrProducts = state.tempItemCatalog.filter(product => product.item_type === 0)
          } else if (state.filterCategory !== 'Voucher' && state.item_type.findIndex(item => item === 1) > -1 && state.item_type.length === 1) {
            console.log(state.item_type);
            console.log('Condition 5 is met');
            arrProducts = state.tempItemCatalog.filter(product => product.item_type === 1)
          } else if (state.filterCategory !== 'Voucher' && state.item_type.findIndex(item => item === 0) > -1 && state.item_type.length === 1) {
            console.log('Condition 6 is met');
            arrProducts = state.tempItemCatalog.filter(product => product.item_type === 0)
          } else if (search !== '') {
            console.log('Condition 7 is met');
            // Nothing TODO
          } else {
            console.log('Condition 8 is met');
            arrProducts = state.tempItemCatalog
          }

          if(arrProducts.length !== state.tempItemCatalog.length){
            commit('setState', {
              itemCatalog: arrProducts
            });
          }
          commit('setState', {
            lastVisible: lastVisible,
            lastVisibleFrom: state.lastVisibleFrom ?? 'get-catalog',
            digitalCount: digitalProductCount,
            physicalCount: physicalProductCount
          });
        }
      } catch (e) {
        console.log(e);
      }
      setTimeout(() => {
        commit('setState', {
          isLoading: false,
        });
      }, 1250);        
      console.log('getCatalogs - end', state.itemCatalog)
    },
    async getCatalogsV2({ commit, state }, payload) {
      console.log('getCatalogs v2', state.itemCatalog)
      commit('setState', {
        isLoading: true,
      });
      if(!localStorage.getItem('fetching-region')) {
        const resp = await MolaMfpAPI.getRegion();
        state.region = resp.data?.continentCode ?? 'AS'
      }
      let db;
      if (state.region === 'EU') {
        db = dbUK
      } else {
        db = dbId
      }
      console.log('state.filter')
      console.log(state.filter)
      
      commit('setFilterMin', null);
      commit('setFilterMax', null);
      // commit('setSearch', '');
      commit('setState', {sortFilter: payload.sortFilter});
      commit('setState', {sortName: payload.sortName});
      console.log("Query Catalog", payload);
      let arrProducts = [];
      const limit = payload.limit || 100;
      const filter = payload.filter ?? '';
      const search = payload.search ?? '';
      const orderBy = payload.orderBy || 'title';
      const orderState = payload.orderState || 'asc';
      commit('setOrderBy', orderBy);
      commit('setOrderState', orderState);
      console.log(filter)
      console.log(search)

      if (filter === '' && search === '' && payload.sortFilter === 1) {
        console.log('does this code setOrderBy happen?')
        if (state.orderBy !== 'publish') {
          commit('setOrderBy', 'item_price_mola_points');
        } 
      }

      let query
      if (payload.sortFilter === 1) {
        console.log("Query Default 1")
        query = db
        .collection('catalog')
        .where('status', '==', 2)
        .orderBy(state.orderBy, state.orderState)
        .limit(limit);
      } else {
        console.log("Query Default 2")
        query = db
        .collection('catalog')
        .where('status', '==', 2)
        .orderBy('importance', 'asc')
        .orderBy(state.orderBy, state.orderState)
        .limit(limit);
      }

      if (filter !== '') {
        console.log("Query 1")
        arrProducts = [];
        query = db
          .collection('catalog')
          .where('status', '==', 2)
          .where('category', '==', filter)
          .orderBy(orderBy, orderState)
          .limit(limit);
      }
      if (search !== '') {
        console.log("Query 2", search)
        arrProducts = [];
        let searchFilter = search.split(' ');
        query = db
          .collection('catalog')
          .where('status', '==', 2)
          .where('keywords', 'array-contains-any', searchFilter)
          .orderBy(orderBy, orderState)
      }
      if(orderBy === 'publish' && state.filterCategory === '') {
        console.log("Query 3")
        if(![undefined,null, ''].includes(payload.min)) {
          commit('setFilterMin', payload.min);
          console.log("Query 3.1")
          query = db
            .collection('catalog')
            .where('status', '==', 2)
            .where('item_price_mola_points', '>=', parseInt(payload.min))
            .orderBy('item_price_mola_points', 'asc')
            .orderBy(orderBy, orderState)
          
            commit('setOrderBy', 'item_price_mola_points');
            commit('setOrderState', orderState);
        }

        if(![undefined,null, ''].includes(payload.max)){
          commit('setFilterMax', payload.max);
          console.log("Query 3.2")
          query = db
            .collection('catalog')
            .where('status', '==', 2)
            .where('item_price_mola_points', '<=', parseInt(payload.max))
            .orderBy('item_price_mola_points', 'asc')
            .orderBy(orderBy, orderState)

            commit('setOrderBy', 'item_price_mola_points');
            commit('setOrderState', orderState);
        }

        if(![undefined,null, ''].includes(payload.min) && ![undefined,null,''].includes(payload.max)){
          commit('setFilterMin', payload.min);
          commit('setFilterMax', payload.max);
          console.log("Query 3.3")
          query = db
            .collection('catalog')
            .where('status', '==', 2)
            .where('item_price_mola_points', '>=', parseInt(payload.min))
            .where('item_price_mola_points', '<=', parseInt(payload.max))
            .orderBy('item_price_mola_points', 'asc')
            .orderBy(orderBy, orderState)

            commit('setOrderBy', 'item_price_mola_points');
            commit('setOrderState', orderState);
        }

      } else if (orderBy === 'publish' && state.filterCategory !== '') {

        console.log("Query 10")
        arrProducts = [];
        query = db
          .collection('catalog')
          .where('status', '==', 2)
          .where('category', '==', state.filterCategory)
          .orderBy(orderBy, orderState)
          .limit(limit);

        if(![undefined,null, ''].includes(payload.min)){
          commit('setFilterMin', payload.min);
          console.log("Query 3.4")
          query = db
            .collection('catalog')
            .where('status', '==', 2)
            .where('category', '==', state.filterCategory)
            .where('item_price_mola_points', '>=', parseInt(payload.min))
            .orderBy('item_price_mola_points', 'asc')
            .orderBy(orderBy, orderState)

            commit('setOrderBy', 'item_price_mola_points');
            commit('setOrderState',orderState);
        }

        if(![undefined,null, ''].includes(payload.max)){
          commit('setFilterMax', payload.max);
          console.log("Query 3.5")
          query = db
            .collection('catalog')
            .where('status', '==', 2)
            .where('category', '==', state.filterCategory)
            .where('item_price_mola_points', '<=', parseInt(payload.max))
            .orderBy('item_price_mola_points', 'asc')
            .orderBy(orderBy, orderState)

            commit('setOrderBy', 'item_price_mola_points');
            commit('setOrderState', orderState);
        }

        if(![undefined,null, ''].includes(payload.min) && ![undefined,null,''].includes(payload.max)){
          commit('setFilterMin', payload.min);
          commit('setFilterMax', payload.max);
          console.log("Query 3.6")
          query = db
            .collection('catalog')
            .where('status', '==', 2)
            .where('category', '==', state.filterCategory)
            .where('item_price_mola_points', '>=', parseInt(payload.min))
            .where('item_price_mola_points', '<=', parseInt(payload.max))
            .orderBy('item_price_mola_points', 'asc')
            .orderBy(orderBy, orderState)

            commit('setOrderBy', 'item_price_mola_points');
            commit('setOrderState', orderState);
        }

      } else if (search !== '') {

        console.log('################ search Catalog v2 reached ################ ')
        if (![undefined,null, ''].includes(payload.min) && ![undefined,null, ''].includes(payload.max)) {

          console.log('search Query search 1')
          arrProducts = [];
          let searchFilter = search.split(' ');
          console.log(state.orderBy);
          console.log(state.orderState);
          query = db
            .collection('catalog')
            .where('status', '==', 2)
            .where('item_price_mola_points', '>=', parseInt(payload.min))
            .where('item_price_mola_points', '<=', parseInt(payload.max))
            .where('keywords', 'array-contains-any', searchFilter)
            .orderBy(state.orderBy, state.orderState)

        } else if (![undefined,null, ''].includes(payload.min)) {

          console.log('search Query search 2')
          arrProducts = [];
          let searchFilter = search.split(' ');
          console.log(state.orderBy);
          console.log(state.orderState);
          query = db
            .collection('catalog')
            .where('status', '==', 2)
            .where('item_price_mola_points', '>=', parseInt(payload.min))
            .where('keywords', 'array-contains-any', searchFilter)
            .orderBy(state.orderBy, state.orderState)

        } else if (![undefined,null, ''].includes(payload.max)) {

          console.log('search Query search 3')
          arrProducts = [];
          let searchFilter = search.split(' ');
          console.log(state.orderBy);
          console.log(state.orderState);
          query = db
            .collection('catalog')
            .where('status', '==', 2)
            .where('item_price_mola_points', '<=', parseInt(payload.max))
            .where('keywords', 'array-contains-any', searchFilter)
            .orderBy(state.orderBy, state.orderState)

        }
        
      } else {

        if (state.filterCategory === '') {
          console.log("Query 4")

        
          if(![undefined,null, ''].includes(payload.min)){
            commit('setFilterMin', payload.min);
            console.log("Query 4.1")
            query = db
              .collection('catalog')
              .where('status', '==', 2)
              .where('item_price_mola_points', '>=', parseInt(payload.min))
              .orderBy(orderBy === 'title' ? 'item_price_mola_points' : orderBy, orderState)

              commit('setOrderBy', 'item_price_mola_points');
              commit('setOrderState', orderState);
          }

          if(![undefined,null, ''].includes(payload.max)){
            console.log("Query 4.2")
            commit('setFilterMax', payload.max);
            query = db
              .collection('catalog')
              .where('status', '==', 2)
              .where('item_price_mola_points', '<=', parseInt(payload.max))
              .orderBy(orderBy === 'title' ? 'item_price_mola_points' : orderBy, orderState)

              commit('setOrderBy', 'item_price_mola_points');
              commit('setOrderState', orderState);
          }

          if(![undefined,null, ''].includes(payload.min) && ![undefined,null, ''].includes(payload.max)){
            console.log("Query 4.3")
            commit('setFilterMin', payload.min);
            commit('setFilterMax', payload.max);
            query = db
              .collection('catalog')
              .where('status', '==', 2)
              .where('item_price_mola_points', '>=', parseInt(payload.min))
              .where('item_price_mola_points', '<=', parseInt(payload.max))
              .orderBy(orderBy === 'title' ? 'item_price_mola_points' : orderBy, orderState)

              commit('setOrderBy', 'item_price_mola_points');
              commit('setOrderState', orderState);
          }

          if([undefined,null, ''].includes(payload.min) && [undefined,null, ''].includes(payload.max) && payload.sortFilter === 1){
            console.log("Query 4.4")
            commit('setFilterMin', payload.min);
            commit('setFilterMax', payload.max);
            query = db
              .collection('catalog')
              .where('status', '==', 2)
              .orderBy(orderBy === 'title' ? 'item_price_mola_points' : orderBy, orderState)

              commit('setOrderBy', 'item_price_mola_points');
              commit('setOrderState', orderState);
          }
        } else {

          console.log("Query 5")
          arrProducts = [];
          query = db
            .collection('catalog')
            .where('status', '==', 2)
            .where('category', '==', state.filterCategory)
            .orderBy(orderBy === 'title' ? 'item_price_mola_points' : orderBy, orderState)
            .limit(limit);

            commit('setOrderBy', 'item_price_mola_points');
            commit('setOrderState', orderState);

          if(![undefined,null, ''].includes(payload.min)){
            commit('setFilterMin', payload.min);
            console.log("Query 5.1")
            query = db
              .collection('catalog')
              .where('status', '==', 2)
              .where('category', '==', state.filterCategory)
              .where('item_price_mola_points', '>=', parseInt(payload.min))
              .orderBy(orderBy === 'title' ? 'item_price_mola_points' : orderBy, orderState)

              commit('setOrderBy', 'item_price_mola_points');
              commit('setOrderState', orderState);
          }

          if(![undefined,null, ''].includes(payload.max)){
            console.log("Query 5.2")
            commit('setFilterMax', payload.max);
            query = db
              .collection('catalog')
              .where('status', '==', 2)
              .where('category', '==', state.filterCategory)
              .where('item_price_mola_points', '<=', parseInt(payload.max))
              .orderBy(orderBy === 'title' ? 'item_price_mola_points' : orderBy, orderState)

              commit('setOrderBy', 'item_price_mola_points');
              commit('setOrderState', orderState);
          }

          if(![undefined,null, ''].includes(payload.min) && ![undefined,null, ''].includes(payload.max)){
            console.log("Query 5.3")
            commit('setFilterMin', payload.min);
            commit('setFilterMax', payload.max);
            query = db
              .collection('catalog')
              .where('status', '==', 2)
              .where('category', '==', state.filterCategory)
              .where('item_price_mola_points', '>=', parseInt(payload.min))
              .where('item_price_mola_points', '<=', parseInt(payload.max))
              .orderBy(orderBy === 'title' ? 'item_price_mola_points' : orderBy, orderState)

              commit('setOrderBy', 'item_price_mola_points');
              commit('setOrderState', orderState);
          }
        }
      }

      try {
          console.log("Query Catalog", query);
          const snapshot = await query.get();
          console.log("Query Catalog", snapshot.size);
          snapshot.forEach((doc) => {
            const obj = doc.data();
            obj.id = doc.id;
            // console.log(obj.title, obj.publish, obj.created)
            arrProducts.push(obj);
          });
          
          const digitalProductCount = arrProducts.filter(product => product.item_type === 0).length;
          const physicalProductCount = arrProducts.filter(product => product.item_type === 1).length;

          console.log('state.filter')
          console.log(state.filter)
          let arrProductsTemp = arrProducts
          console.log('arrProductsTemp getCatalogsV2')
          console.log(arrProductsTemp)
          if (state.filter === 'Digital') {
            arrProducts = arrProducts.filter(product => product.item_type === 0)
          }
          if (state.filter === 'Physical') {
            arrProducts = arrProducts.filter(product => product.item_type === 1)
          }
          console.log(orderState)
          if(orderBy === 'publish'){
            if(orderState === 'asc') arrProducts.sort((a,b) => {console.log("Sorting ASC:", a.publish, b.publish); return a.publish - b.publish})
            if(orderState === 'desc') arrProducts.sort((a,b) => {console.log("Sorting DESC:", b.publish, a.publish); return b.publish - a.publish})
            console.log("Product", arrProducts)
          }
          commit('setState', {
            itemCatalog: arrProducts,
            tempItemCatalog: arrProductsTemp,
            digitalCount: digitalProductCount,
            physicalCount: physicalProductCount
          });
      } catch (e) {
        console.log(e);
      }
      commit('setState', {
        isLoading: false,
      });
      console.log('getCatalogs - v2 end', state.itemCatalog)
    },
    async getCategories({ commit, state }) {
      if(!localStorage.getItem('fetching-region')) {
        const resp = await MolaMfpAPI.getRegion();
        state.region = resp.data?.continentCode ?? 'AS'
      }
      let db = dbId
      if (state.region === 'EU') {
        db = dbUK
      }
      let arrCategory = [];
      let query = db.collection('category')
      .where('name', "!=", "")
      const snapshot = await query.get();
      snapshot.forEach((doc) => {
        const obj = doc.data();
        obj.id = doc.id;
        arrCategory.push(obj);
      });
      commit('setState', {
        categories: arrCategory,
      });
    },
    async addRedeemed({ commit, state }, payload) {
      const arrCatalog = state.itemCatalog;
      const index = arrCatalog.findIndex((x) => x.id === payload.item_id);
      arrCatalog[index].redeemed += payload.redeemed;
      commit('setState', {
        itemCatalog: arrCatalog,
      });
    },
    async addRedeemedVariant({ commit, state }, payload) {
      const arrCatalog = state.itemCatalog;
      const index = arrCatalog.findIndex((x) => x.id === payload.item_id);
      console.log('addRedeemedVariant', arrCatalog[index]);
      arrCatalog[index].variants = payload.variants;
      console.log('addRedeemedVariant', arrCatalog[index]);
      commit('setState', {
        itemCatalog: arrCatalog,
      });
    },
    setProductDetail({commit}, payload){
      commit('setState', {
        productDetail: payload
      })
    },
    async countViews({commit}, payload){
      console.log(payload);
      await db.collection('catalog').doc(payload.id).update(
        {
          views: firebase.firestore.FieldValue.increment(1),
        }
      );
      commit('setState', {
        message: ''
      })
    },
    async getPopularItem({commit, state}){
      if(!localStorage.getItem('fetching-region')) {
        const resp = await MolaMfpAPI.getRegion();
        state.region = resp.data?.continentCode ?? 'AS'
      }
      let db = dbId
      if (state.region === 'EU') {
        db = dbUK
      }
      const query = db
        .collection('catalog')
        .where('status', '==', 2)
        .orderBy('positionPopular', 'asc')
        .limit(5)
      const arrProducts = [];
      try {
          const snapshot = await query.get();
          snapshot.forEach((doc) => {
            let obj = doc.data();
            obj.id = doc.id;
            obj.default = false
            obj.positionPopular = obj.positionPopular ?? 100000
            arrProducts.push(obj);
          });
          commit('setState', {
            popularItem: arrProducts
          });
          if (snapshot.empty) {
            const query = db
            .collection('catalog')
            .where('status', '==', 2)
            .orderBy('views', 'desc')
            .limit(3)
            const snapshot2 = await query.get();
            snapshot2.forEach((doc) => {
              let obj = doc.data();
              obj.id = doc.id;
              obj.positionPopular = obj.positionPopular ?? 100000
              obj.default = true
              arrProducts.push(obj);
            });
            commit('setState', {
              popularItem: arrProducts
            });
          }
      } catch (e) {
        console.log(e);
      }
    },
    async getNewItem({commit, state}){
      if(!localStorage.getItem('fetching-region')) {
        const resp = await MolaMfpAPI.getRegion();
        state.region = resp.data?.continentCode ?? 'AS'
      }
      let db = dbId
      if (state.region === 'EU') {
        db = dbUK
      }
      const query = db
        .collection('catalog')
        .where('status', '==', 2)
        .orderBy('positionNew', 'asc')
        .limit(5)
      const arrProducts = [];
      try {
          const snapshot = await query.get();
          snapshot.forEach((doc) => {
            let obj = doc.data();
            obj.id = doc.id;
            obj.default = false
            obj.positionNew = obj.positionNew ?? 100000
            arrProducts.push(obj);
          });
          commit('setState', {
            newItem: arrProducts
          });
          if (snapshot.empty) {
            const query = db
            .collection('catalog')
            .where('status', '==', 2)
            .orderBy('publish', 'desc')
            .limit(3)
            const snapshot2 = await query.get();
            snapshot2.forEach((doc) => {
              let obj = doc.data();
              obj.id = doc.id;
              obj.default = true
              obj.positionNew = obj.positionNew ?? 100000
              arrProducts.push(obj);
            });
            commit('setState', {
              newItem: arrProducts
            });
          }
          
      } catch (e) {
        console.log(e);
      }
    },
    productTypeFilter({commit, state}, payload){
      let tempProducts = [];
      let arrProducts = [];

      console.log('######## state.payload ############')
      console.log(payload)
      
      if (state.filterCategory !== '') {
        let arrProducts = []
        console.log('state.tempItemCatalog.length')
        console.log(state.tempItemCatalog.length)
        if(state.tempItemCatalog.length === 0){
          commit('setState', {
            tempItemCatalog: state.itemCatalog
          })
          tempProducts = state.itemCatalog
        } else {
          tempProducts = state.tempItemCatalog
        }
        if(payload === 'Digital'){
          if (![undefined,null, ''].includes(state.filterMin) && ![undefined,null, ''].includes(state.filterMax)) {
            arrProducts = tempProducts.filter(product => product.item_type === 0 && product.item_price_mola_points >= state.filterMin && product.item_price_mola_points <= state.filterMax && product.category === state.filterCategory)
          } else if (![undefined,null, ''].includes(state.filterMin) && [undefined,null, ''].includes(state.filterMax)) {
            arrProducts = tempProducts.filter(product => product.item_type === 0 && product.item_price_mola_points >= state.filterMin && product.category === state.filterCategory)
          } else if ([undefined,null, ''].includes(state.filterMin) && ![undefined,null, ''].includes(state.filterMax)) {
            arrProducts = tempProducts.filter(product => product.item_type === 0 && product.item_price_mola_points <= state.filterMax && product.category === state.filterCategory)
          } else {
            arrProducts = tempProducts.filter(product => product.item_type === 0 && product.category === state.filterCategory)
          }
          console.log(arrProducts)
          commit('setState', {
            itemCatalog: arrProducts,
            filter: payload
          })
          commit('setState', {
            item_type: payload === 'Physical' ? [1] : [0]
          });
          return
        } else {
          if (![undefined,null, ''].includes(state.filterMin) && ![undefined,null, ''].includes(state.filterMax)) {
            arrProducts = tempProducts.filter(product => product.item_type === 1 && product.item_price_mola_points >= state.filterMin && product.item_price_mola_points <= state.filterMax && product.category === state.filterCategory)
          } else if (![undefined,null, ''].includes(state.filterMin) && [undefined,null, ''].includes(state.filterMax)) {
            arrProducts = tempProducts.filter(product => product.item_type === 1 && product.item_price_mola_points >= state.filterMin && product.category === state.filterCategory)
          } else if ([undefined,null, ''].includes(state.filterMin) && ![undefined,null, ''].includes(state.filterMax)) {
            arrProducts = tempProducts.filter(product => product.item_type === 1 && product.item_price_mola_points <= state.filterMax && product.category === state.filterCategory)
          } else {
            arrProducts = tempProducts.filter(product => product.item_type === 1 && product.category === state.filterCategory)
          }
        }
        console.log(arrProducts)
        commit('setState', {
          itemCatalog: arrProducts,
          filter: payload
        })
        commit('setState', {
          item_type: payload === 'Physical' ? [1] : [0]
        });
        return
      }

      console.log('########### filter values ############')
      console.log(state.filterMin)
      console.log(state.filterMax)

      console.log('state.tempItemCatalog.length')
      console.log(state.tempItemCatalog.length)

      if(state.tempItemCatalog.length === 0){
        commit('setState', {
          tempItemCatalog: state.itemCatalog
        })
        tempProducts = state.itemCatalog
      } else {
        tempProducts = state.tempItemCatalog
      }
      
      console.log('Product Type')
      console.log(payload)

      if(payload === 'Digital'){
        if (![undefined,null, ''].includes(state.filterMin) && ![undefined,null, ''].includes(state.filterMax)) {
          arrProducts = tempProducts.filter(product => product.item_type === 0 && product.item_price_mola_points >= state.filterMin && product.item_price_mola_points <= state.filterMax)
        } else if (![undefined,null, ''].includes(state.filterMin) && [undefined,null, ''].includes(state.filterMax)) {
          arrProducts = tempProducts.filter(product => product.item_type === 0 && product.item_price_mola_points >= state.filterMin)
        } else if ([undefined,null, ''].includes(state.filterMin) && ![undefined,null, ''].includes(state.filterMax)) {
          arrProducts = tempProducts.filter(product => product.item_type === 0 && product.item_price_mola_points <= state.filterMax)
        } else {
          arrProducts = tempProducts.filter(product => product.item_type === 0)
        }
      } else {
        if (![undefined,null, ''].includes(state.filterMin) && ![undefined,null, ''].includes(state.filterMax)) {
          arrProducts = tempProducts.filter(product => product.item_type === 1 && product.item_price_mola_points >= state.filterMin && product.item_price_mola_points <= state.filterMax)
        } else if (![undefined,null, ''].includes(state.filterMin) && [undefined,null, ''].includes(state.filterMax)) {
          arrProducts = tempProducts.filter(product => product.item_type === 1 && product.item_price_mola_points >= state.filterMin)
        } else if ([undefined,null, ''].includes(state.filterMin) && ![undefined,null, ''].includes(state.filterMax)) {
          arrProducts = tempProducts.filter(product => product.item_type === 1 && product.item_price_mola_points <= state.filterMax)
        } else {
          arrProducts = tempProducts.filter(product => product.item_type === 1)
        }
      }
      commit('setState', {
        itemCatalog: arrProducts,
        filter: payload
      })
      commit('setState', {
        item_type: payload === 'Physical' ? [1] : [0]
      });
      console.log('state.filter')
      console.log(state.filter)
    },
    restoreProductTypeFilter({commit, state}, payload) {
      
      let tempProducts = state.tempItemCatalog;
      console.log(tempProducts)
      console.log(payload)

      if (payload === 'Digital') {
        let arrProducts = [];
        if (![undefined,null, ''].includes(state.filterMin) && ![undefined,null, ''].includes(state.filterMax)) {
          arrProducts = tempProducts.filter(product => product.item_price_mola_points >= state.filterMin && product.item_price_mola_points <= state.filterMax)
        } else if (![undefined,null, ''].includes(state.filterMin) && [undefined,null, ''].includes(state.filterMax)) {
          arrProducts = tempProducts.filter(product => product.item_price_mola_points >= state.filterMin)
        } else if ([undefined,null, ''].includes(state.filterMin) && ![undefined,null, ''].includes(state.filterMax)) {
          arrProducts = tempProducts.filter(product => product.item_price_mola_points <= state.filterMax)
        }
        commit('setState', {
          itemCatalog: arrProducts,
          filter: payload
        })
      } else if (payload === 'Physical') {
        let arrProducts = [];
        if (![undefined,null, ''].includes(state.filterMin) && ![undefined,null, ''].includes(state.filterMax)) {
          arrProducts = tempProducts.filter(product => product.item_price_mola_points >= state.filterMin && product.item_price_mola_points <= state.filterMax)
        } else if (![undefined,null, ''].includes(state.filterMin) && [undefined,null, ''].includes(state.filterMax)) {
          arrProducts = tempProducts.filter(product => product.item_price_mola_points >= state.filterMin)
        } else if ([undefined,null, ''].includes(state.filterMin) && ![undefined,null, ''].includes(state.filterMax)) {
          arrProducts = tempProducts.filter(product => product.item_price_mola_points <= state.filterMax)
        }
        commit('setState', {
          itemCatalog: arrProducts,
          filter: payload
        })
      } else {
        let arrProducts = [];
        if (![undefined,null, ''].includes(state.filterMin) && ![undefined,null, ''].includes(state.filterMax)) {
          arrProducts = tempProducts.filter(product => product.item_price_mola_points >= state.filterMin && product.item_price_mola_points <= state.filterMax)
        } else if (![undefined,null, ''].includes(state.filterMin) && [undefined,null, ''].includes(state.filterMax)) {
          arrProducts = tempProducts.filter(product => product.item_price_mola_points >= state.filterMin)
        } else if ([undefined,null, ''].includes(state.filterMin) && ![undefined,null, ''].includes(state.filterMax)) {
          arrProducts = tempProducts.filter(product => product.item_price_mola_points <= state.filterMax)
        }
        commit('setState', {
          itemCatalog: arrProducts,
          filter: payload
        })
      }

      /* if (![undefined,null, ''].includes(state.filterMin) || ![undefined,null, ''].includes(state.filterMax)) {
        console.log('######## return state.filterCategory ############')
        return
      } */

      console.log('######## state.filterCategory ############')
      console.log(state.filterCategory)
      
      if (state.filterCategory !== '') {
        let arrProducts = []
        console.log('state.tempItemCatalog.length')
        console.log(state.tempItemCatalog.length)
        if(state.tempItemCatalog.length === 0){
          commit('setState', {
            tempItemCatalog: state.itemCatalog
          })
          tempProducts = state.itemCatalog
        } else {
          tempProducts = state.tempItemCatalog
        }
        if(payload === 'Digital'){
          if (![undefined,null, ''].includes(state.filterMin) && ![undefined,null, ''].includes(state.filterMax)) {
            arrProducts = tempProducts.filter(product =>  product.item_price_mola_points >= state.filterMin && product.item_price_mola_points <= state.filterMax && product.category === state.filterCategory)
          } else if (![undefined,null, ''].includes(state.filterMin) && [undefined,null, ''].includes(state.filterMax)) {
            arrProducts = tempProducts.filter(product =>  product.item_price_mola_points >= state.filterMin && product.category === state.filterCategory)
          } else if ([undefined,null, ''].includes(state.filterMin) && ![undefined,null, ''].includes(state.filterMax)) {
            arrProducts = tempProducts.filter(product =>  product.item_price_mola_points <= state.filterMax && product.category === state.filterCategory)
          } else {
            arrProducts = tempProducts.filter(product =>  product.category === state.filterCategory)
          }
        } else {
          if (![undefined,null, ''].includes(state.filterMin) && ![undefined,null, ''].includes(state.filterMax)) {
            arrProducts = tempProducts.filter(product => product.item_price_mola_points >= state.filterMin && product.item_price_mola_points <= state.filterMax && product.category === state.filterCategory)
          } else if (![undefined,null, ''].includes(state.filterMin) && [undefined,null, ''].includes(state.filterMax)) {
            arrProducts = tempProducts.filter(product => product.item_price_mola_points >= state.filterMin && product.category === state.filterCategory)
          } else if ([undefined,null, ''].includes(state.filterMin) && ![undefined,null, ''].includes(state.filterMax)) {
            arrProducts = tempProducts.filter(product => product.item_price_mola_points <= state.filterMax && product.category === state.filterCategory)
          } else {
            arrProducts = tempProducts.filter(product => product.category === state.filterCategory)
          }
        }
        console.log(arrProducts)
        commit('setState', {
          itemCatalog: arrProducts,
          filter: payload
        })
        commit('setState', {
          item_type: [0,1]
        });
        return
      }

      let arrProducts = state.tempItemCatalog

      commit('setState', {
        itemCatalog: arrProducts,
        filter: ''
      })
      commit('setState', {
        item_type: [0,1]
      });
    }
  },
  getters: {},
};
