const FIREBASE_CONFIG_TEST_ENV = {
  apiKey: "AIzaSyDwykZplUdocrpHI1qSmI72NONscZ7PQ28",
  authDomain: "mola-catalog-staging.firebaseapp.com",
  projectId: "mola-catalog-staging",
  storageBucket: "mola-catalog-staging.appspot.com",
  messagingSenderId: "764525587588",
  appId: "1:764525587588:web:54675a3989a7809d11a6d1",
  measurementId: "G-7HZSVNNZDR"
};

const FIREBASE_CONFIG_PROD_ENV = {
  apiKey: "AIzaSyB-__JNdvotos1wpPx06FNRtwcxplNrrkA",
  authDomain: "mola-catalog-production.firebaseapp.com",
  projectId: "mola-catalog-production",
  storageBucket: "mola-catalog-production.appspot.com",
  messagingSenderId: "247670580356",
  appId: "1:247670580356:web:0359327cabbd63d8744ed1",
  measurementId: "G-0M5Y2VCG90"
};

var FIREBASE_CONFIG = {};

if (process.env.VUE_APP_ENVIRONMENT === "production") {
  console.log("PROD ENVIRONMENT");
  FIREBASE_CONFIG = FIREBASE_CONFIG_PROD_ENV;
} else if (process.env.VUE_APP_ENVIRONMENT === "staging") {
  console.log("STAGING ENVIRONMENT");
  FIREBASE_CONFIG = FIREBASE_CONFIG_TEST_ENV;
} else {
  console.log("LOCAL ENVIRONMENT");
  FIREBASE_CONFIG = FIREBASE_CONFIG_TEST_ENV;
}

export default FIREBASE_CONFIG;
