<template>
   <v-container class="mx-auto mt-2">
                      <!-- <v-row class="px-md-6 pt-md-6 pb-md-2">
                        <v-col cols="12" class="font-weight-bold py-2">
                          Customer Reviews
                        </v-col>
                        <v-col cols="12" class="py-0">
                          <span @click="alert('5 Star')" class="filterLink">
                          <v-rating
                            hover
                            length="5"
                            readonly
                            dense
                            size="16"
                            :value="5"
                          ></v-rating>
                          </span>
                        </v-col>
                        <v-col cols="12" class="py-0" >
                          <span @click="alert('4 Star')" class="filterLink">
                          <v-rating
                            hover
                            dense
                            length="5"
                            readonly
                            size="16"
                            :value="4"
                          ></v-rating>
                          </span>

                        </v-col>
                        <v-col cols="12" class="py-0">
                          <span @click="alert('3 Star')" class="filterLink">
                          <v-rating
                            hover
                            length="5"
                            readonly
                            dense
                            size="16"
                            :value="3"
                          ></v-rating>
                          </span>
                        </v-col>
                      </v-row> -->
                      <!-- <v-row class="pl-md-6 py-md-2">
                        <v-col cols="12" class="font-weight-bold py-2">
                          Most Views by Customer
                        </v-col>
                        <v-col cols="12" class="py-0">
                          <span @click="alert('Physical')" class="filterLink">
                          Mola Collectibles Collection
                          </span>
                        </v-col>
                        <v-col cols="12" class="py-0">
                          <span @click="alert('Physical')" class="filterLink">
                          Mola TV Subscription
                          </span>
                        </v-col>
                      </v-row> -->
                      <v-row class="pa-md-2 py-md-2">
                        <v-col cols="12" class="font-weight-bold py-2">
                          {{lang[getLanguage].PRODUCT_TYPE}}
                        </v-col>
                        <v-col cols="12" class="py-0">
                          <span @click="filterTypeProduct('Physical')" class="filterLink" :style="type === 'Physical' ? 'font-weight:bold;color:#1876d2' : ''">
                            {{lang[getLanguage].PHYSICAL}} ({{ physicalCount }})
                          </span>
                        </v-col>
                        <v-col cols="12" class="py-0">
                          <span @click="filterTypeProduct('Digital')" class="filterLink" :style="type === 'Digital' ? 'font-weight:bold;color:#1876d2' : ''">
                            {{lang[getLanguage].DIGITAL}} ({{ digitalCount }})
                          </span>
                        </v-col>
                      </v-row>

                      <v-row class="pa-md-2 py-md-2">
                        <v-col cols="12" class="font-weight-bold py-2">
                          {{lang[getLanguage].SORT_BY}}
                        </v-col>
                        <v-col cols="12" md="12" class="py-md-0 pr-md-0">
                          <v-combobox 
                            v-model="sortBy"
                            :placeholder="lang[getLanguage].SORT"
                            outlined
                            hide-details
                            :items="sorts"
                            type="text"
                            dense></v-combobox>
                        </v-col>
                      </v-row>

                      <v-row class="pa-md-2 py-md-2">
                        <v-col cols="12" class="font-weight-bold py-2">
                          {{lang[getLanguage].FILTER_BY_POINTS}}
                        </v-col>
                        <v-col cols="12" md="5" class="py-md-0 pr-md-0">
                          <v-text-field
                            v-model="min"
                            :label="lang[getLanguage].MINIMUM"
                            outlined
                            hide-details
                            type="text"
                            :min="0"
                            dense
                            @keypress="filter(event)"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="2" class="py-0 px-2 d-flex justify-center text-center" v-if="!$vuetify.breakpoint.mobile">
                          <span class="text-h5 text-center">
                            ~
                          </span>
                        </v-col>
                        <v-col cols="12" md="5" class="py-md-0 pl-md-0">
                          <v-text-field
                            style="width: 100%;"
                            v-model="max"
                            :label="lang[getLanguage].MAXIMUM"
                            outlined
                            hide-details
                            type="text"
                            :min="0"
                            dense
                            @keypress="filter(event)"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <!-- <v-row class="pa-md-2 py-md-2">
                        <v-col cols="12" class="font-weight-bold py-2">
                          Today Deals
                        </v-col>
                        <v-col cols="12" class="py-0">
                          <span @click="alert('Physical')" class="filterLink">
                          Mola TV Subscription
                          </span>
                        </v-col>
                        <v-col cols="12" class="py-0">
                          <span @click="alert('Physical')" class="filterLink">
                          Mola Jersey
                          </span>
                        </v-col>
                      </v-row> -->
                      <v-row class="pa-md-2 py-md-2">
                        <v-col cols="12" class="font-weight-bold py-2">
                          {{lang[getLanguage].NEW_ARRIVALS}}
                        </v-col>
                        <v-col cols="12" class="py-0" v-for="item in newItem" :key="item.id">
                          <span @click="openDetail(item)" class="filterLink" v-if="item.positionNew !== 100000 || item.default">
                            {{ item.title }}
                          </span>
                        </v-col>
                        <!-- <v-col cols="12" class="py-0">
                          <span @click="alert('Physical')" class="filterLink">
                          Coldplay Ticket
                          </span>
                        </v-col> -->
                      </v-row>
                      <v-row class="pa-md-2 py-md-2">
                        <v-col cols="12" class="font-weight-bold py-2">
                          {{lang[getLanguage].POPULAR_ITEMS}}
                        </v-col>
                        <v-col cols="12" class="py-0" v-for="item in popularItem" :key="item.id">
                          <span @click="openDetail(item)" class="filterLink" v-if="item.positionPopular !== 100000 || item.default" >
                            {{ item.title }}
                          </span>
                        </v-col>
                      </v-row>
    </v-container>
    
</template>

<script>
export default {
    props: {
    dark: Boolean,
    windowWidth: Number,
    windowHeight: Number,
    drawer: Boolean
  },
  data() {
    return {
      min: null,
      max: null,
      sortBy: null,
      sorts: [],//['Mola Points: Low to High', 'Mola Points: High to Low', 'Newest', 'Oldest'],
      orderBy: '',
      orderState: '',
      type: '',
    }
  },
  computed:{
    digitalCount(){
      return this.$store.state.catalog.digitalCount;
    },
    physicalCount(){
      return this.$store.state.catalog.physicalCount;
    },
    popularItem(){
      return this.$store.state.catalog.popularItem;
    },
    newItem(){
      return this.$store.state.catalog.newItem;
    },
    sortName(){
      return this.$store.state.catalog.sortName;
    },
    filterMin(){
      return this.$store.state.catalog.filterMin;
    },
    filterMax(){
      return this.$store.state.catalog.filterMax;
    },
    sortFilter(){
      return this.$store.state.catalog.sortFilter;
    },
    orderByComp(){
      return this.$store.state.catalog.orderBy;
    },
    orderStateComp(){
      return this.$store.state.catalog.orderState;
    },
    typeFilter(){
      return this.$store.state.catalog.item_type;
    },
    search(){
      return this.$store.state.catalog.search;
    },
    lang(){
      return this.$store.state.lang;
    },
    getLanguage(){
      return this.$store.state.current_language
    },
  },
  watch:{
    getLanguage(v){
      let oldSorts = this.sorts
      console.log(oldSorts)
      this.sorts = [];
      this.sorts.push(this.lang[v].MOLA_POINTS_LOW_TO_HIGH)
      this.sorts.push(this.lang[v].MOLA_POINTS_HIGH_TO_LOW)
      this.sorts.push(this.lang[v].NEWEST)
      this.sorts.push(this.lang[v].OLDEST)
      this.sorts.push(this.lang[v].CLEAR_FILTER)
      console.log(this.sorts)
      console.log(this.sortBy)
      this.sortBy = this.sorts[oldSorts.findIndex(item => item === this.sortBy)]
      this.reloadLanguage = true
    },
    sortBy(v){
      console.log("getCatalogs FILTER SORT", typeof v, v)
      if([null, undefined, ''].includes(v)) {
        return;
      }

      // this.$store.dispatch('restoreProductTypeFilter')
      if (this.reloadLanguage) {
        this.reloadLanguage = false
        return
      }
      var sortFilter = 0
      var name
      if(v === this.lang[this.getLanguage].MOLA_POINTS_LOW_TO_HIGH){
        this.orderState = 'asc';
        this.orderBy = 'item_price_mola_points'
        sortFilter = 1
        name = this.lang[this.getLanguage].MOLA_POINTS_LOW_TO_HIGH
      }
      if(v === this.lang[this.getLanguage].MOLA_POINTS_HIGH_TO_LOW){
        this.orderState = 'desc';
        this.orderBy = 'item_price_mola_points'
        sortFilter = 1
        name = this.lang[this.getLanguage].MOLA_POINTS_HIGH_TO_LOW
      }
      if(v === this.lang[this.getLanguage].NEWEST){
        this.orderState = 'desc';
        this.orderBy = 'publish';
        sortFilter = 1
        name = this.lang[this.getLanguage].NEWEST
      }
      if(v === this.lang[this.getLanguage].OLDEST){
        this.orderState = 'asc';
        this.orderBy = 'publish';
        sortFilter = 1
        name = this.lang[this.getLanguage].OLDEST
      }
      if(v === this.lang[this.getLanguage].CLEAR_FILTER){
        this.orderState = 'asc';
        this.orderBy = 'item_price_mola_points';
        sortFilter = 0
        this.sortBy = null
        name = ''
      }

      let payload = {
        sortFilter: sortFilter,
        limit: 200,
        search: this.search,
        filter: '',
        orderBy: this.orderBy === '' ? this.orderByComp : this.orderBy,
        orderState: this.orderState,
        min: this.min,
        max: this.max,
        next: false,
        sortName: name
      }

      if(this.min === null || isNaN(this.min)){
        delete payload['min']
      }
      if(this.max === null || isNaN(this.max)){
        delete payload['max']
      }

      this.$store.dispatch("getCatalogsV2", payload);
    },
    min(v){
      console.log("getCatalogs FILTER MIN")
      console.log("Min", v, typeof v)
      const payload = { 
        sortFilter: this.sortFilter,
        limit: 200,
        search: this.search,
        filter: '',
        orderBy: this.orderBy === '' ? this.orderByComp : this.orderBy,
        orderState: this.orderState,
        min: v,
        max: this.max,
        next: false,
        sortName: this.sortName,
      }

      //if min is null / undefined / '', delete min from payload
      if([null,undefined,''].includes(v) || isNaN(v)){
        delete payload['min']
      }
      //if max is null / undefined / '', delete max from payload
      if([null,undefined,''].includes(this.max) || isNaN(this.max)){
        delete payload['max']
      }

      console.log('Min payload')
      console.log(payload)

      this.$store.dispatch("getCatalogsV2", payload);
    },
    max(v){
      console.log("getCatalogs FILTER MAX")

      console.log("Max", v, typeof v)
      const payload = { 
        sortFilter: this.sortFilter,
        limit: 200,
        search: this.search,
        filter: '',
        orderBy: this.orderBy === '' ? this.orderByComp : this.orderBy,
        orderState: this.orderState,
        min: this.min,
        max: v,
        next: false,
        sortName: this.sortName
      }
      console.log("Max", [null,undefined,''].includes(v) || isNaN(v))
      //if max is null / undefined / '', delete max from payload
      if([null,undefined,''].includes(v) || isNaN(v)){
        delete payload['max']
      }
      //if max is null / undefined / '', delete max from payload
      if([null,undefined,''].includes(this.min) || isNaN(this.min)){
        delete payload['min']
      }

      console.log('Max payload')
      console.log(payload)

      this.$store.dispatch("getCatalogsV2", payload);
    },
  },
  created(){
    this.sorts.push(this.lang[this.getLanguage].MOLA_POINTS_LOW_TO_HIGH)
    this.sorts.push(this.lang[this.getLanguage].MOLA_POINTS_HIGH_TO_LOW)
    this.sorts.push(this.lang[this.getLanguage].NEWEST)
    this.sorts.push(this.lang[this.getLanguage].OLDEST)
    this.sorts.push(this.lang[this.getLanguage].CLEAR_FILTER)
    this.$store.dispatch('getPopularItem');
    this.$store.dispatch('getNewItem');

    console.log('############## this.sortName #####################')
    console.log(this.sortName)
    this.sortBy = this.sortName
    this.min = this.filterMin
    this.max = this.filterMax
    this.orderState = this.orderStateComp
    console.log('############## this.typeFilter #####################')
    console.log(this.typeFilter)
    if (this.typeFilter.length === 2) {
      this.type = ''
    } else if (this.typeFilter[0] === 0 && this.typeFilter.length === 1) {
      this.type = 'Digital'
    } else if (this.typeFilter[0] === 1 && this.typeFilter.length === 1) {
      this.type = 'Physical'
    }
  },
  methods: {
    filter (evt) {
      evt = (evt) ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();
      
      if (!/^[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    filterTypeProduct(type){
      console.log(this.type)
      console.log(type)
      if (this.type !== type){
        this.type = type;
        this.$store.dispatch('productTypeFilter', type);
      } else {
        this.type = '';
        this.$store.dispatch('restoreProductTypeFilter', type);
      }
    },
    openDetail(item){
      const itemData = {
        image: item.url,
        title: item.title,
        type: item.item_type,
        category: item.category,
        price: item.item_price_mola_points,
        stock: Number.parseInt(item.item_quantity),
        redeemed: Number.parseInt(item.redeemed ?? 0),
        description: item.story,
        id: item.id,
        isHideRedeem: item.isHideRedeem ?? false,
        isReward: item.isReward ?? false,
        comingSoonDate: item.comingSoonDate ?? null,
        images: item.images ?? [],
        variants: item.variants ?? [],
        variantDetail: item.variantDetail ?? null,
        isVariant: item.isVariant ?? false,
        imageAspectRatio: item.imageAspectRatio ?? 1
      }
      this.$store.dispatch('setProductDetail', itemData);
      this.$router.push('/catalog/' + item.slug);
    }
  }
};
</script>
<style>
.filterLink{
  cursor: pointer;
}
</style>