import { db } from '../main';
import dateformat from 'dateformat';
function makeDate(date){
    return dateformat(new Date(date), 'dd mmm, yyyy');
}
export default {
  state: {
    lastEventTitle: '',
    lastEventId: '',
    lastEventPointArr: [],
    historyPointArr: [],
    pointErrorMessage: '',
    pointLoading: false,
    eventHistory: null,
    historyErrorMessage: '',
    historyLoading: false,
    lastHistory: null,
    stopLoadMoreHistory: false,
    totalPointsWonLastEvent: 0,
    userSwapRequestsArr: [],
    userSwapRequestsEmpty: false
  },
  mutations: {
    setState(state, payload) {
      var keys = Object.keys(payload);
      keys.forEach((key) => {
        state[key] = payload[key];
      });
    },
    setTotalPointsWonLastEvent (state, payload) {
      state.totalPointsWonLastEvent = payload
    },
    setUserSwapRequestsArr (state, payload) {
      state.userSwapRequestsArr = payload
    }, 
    setUserSwapRequestsEmpty (state, payload) {
      state.userSwapRequestsEmpty = payload
    }
  },
  actions: { 
    getLastEventPoint({ commit }, payload) {
      console.log("get last event point")
      commit('setState', {
        pointLoading: true,
      });
      let lastEventPoints = [];
      let eventId = '';
      let eventName = '';
      let query = db
        .collection('events')
        .where('results_processed', '>', 0)
        .orderBy('results_processed', 'desc')
        .limit(1);
      query
        .get()
        .then((snapshot) => {
          console.log(snapshot)
          if (snapshot.empty) {
            commit('setState', {
              pointLoading: false,
              pointErrorMessage: 'No Point History Found',
            });
            return;
          }
          eventId = snapshot.docs[0].id;
          eventName = snapshot.docs[0].data().name;
          let queryPredictions = db
            .collection('predictions')
            .where('event_doc_id', '==', eventId)
            .where('uid', '==', payload.user_id);
          queryPredictions
            .get()
            .then((snapshot) => {
              console.log(snapshot)
              if (snapshot.empty) {
                commit('setState', {
                  pointLoading: false,
                  pointErrorMessage: 'No Point History Found',
                });
                return;
              }
              var totalPointsWon = 0
              snapshot.forEach((doc) => {
                const pointData = doc.data();
                console.log(pointData)
                if (pointData.results_processed > 0) {
                  lastEventPoints.push({
                    date: makeDate(pointData.results_processed),
                    event: eventName,
                    fight: pointData.p_game_name ?? '',
                    mola_sub_type: pointData.mola_sub_type,
                    outcome: pointData.points_won_outcome ?? 0,
                    method: pointData.points_won_method ?? 0,
                    rounds: pointData.points_won_rounds ?? 0,
                    total_point: pointData.points_won,
                  });
                }
                totalPointsWon += pointData.points_won
              });
              const statePayload = {
                lastEventTitle: eventName,
                lastEventId: eventId,
                lastEventPointArr: lastEventPoints,
                pointLoading: false,
              };
              console.log(statePayload);
              commit('setTotalPointsWonLastEvent', totalPointsWon)
              commit('setState', statePayload);
            })
            .catch((err) => {
              console.log(err);
              commit('setState', {
                pointLoading: false,
                pointErrorMessage:
                  err.message ?? 'Error! Something Went Wrong!',
              });
            });
        })
        .catch((err) => {
          console.log(err);
          commit('setState', {
            pointLoading: false,
            pointErrorMessage: err.message ?? 'Error! Something Went Wrong!',
          });
        });
    },
    async getPointHistory({commit, state}, payload){
        console.log(state.historyPointArr);
        console.log('before 1', state.historyPointArr.length);
      commit('setState', {
        historyLoading: true,
      });
      let historyEventPoints = state.historyPointArr;
      if (historyEventPoints.length === 0) {
        historyEventPoints = [...state.lastEventPointArr];
        console.log(historyEventPoints);
        console.log('before 2', historyEventPoints.length);
      }
      let mapEvent = new Map();
      if (state.eventHistory === null) {
        let query = db
          .collection('events')
          .where('results_processed', '>', 0)
          .orderBy('results_processed', 'desc');

        const eventSnapshot = await query.get();
        if(!eventSnapshot.empty)
        {
            eventSnapshot.forEach((doc) => {
              const eventData = doc.data();
              console.log(eventData);
              mapEvent.set(doc.id, eventData.name);
            });
            commit('setState', {
              eventHistory: mapEvent,
            });
        }
      } else {
        mapEvent = state.eventHistory;
      }
      console.log(mapEvent);
      if (mapEvent.size !== 0) {
        let keys = [...mapEvent.keys()];
        const index = keys.indexOf(state.lastEventId);
        if (index > -1) {
          keys.splice(index, 1);
        }
        console.log(keys)
        let historyQuery;
        if ([null, undefined].includes(state.lastHistory)) {
          historyQuery = db
            .collection('predictions')
            .where('event_doc_id', 'in', keys)
            .where('uid', '==', payload.user_id)
            .where('results_processed', ">", 0)
            .limit(payload.limit);
        } else {
          historyQuery = db
            .collection('predictions')
            .where('event_doc_id', 'in', keys)
            .where('uid', '==', payload.user_id)
            .where('results_processed', '>', 0)
            .startAfter(state.lastHistory)
            .limit(payload.limit);
        }
        console.log(keys)
        historyQuery
          .get()
          .then((snapshot) => {
            let stopLoadMore = false;
            const lastVisibleHistory = snapshot.docs[snapshot.docs.length - 1];
            if([undefined, null].includes(lastVisibleHistory)){
                stopLoadMore = true;
            }
            snapshot.forEach((doc) => {
              const pointData = doc.data();
              console.log(pointData);
              console.log(pointData.results_processed);
              if (pointData.results_processed >= 0) {
                console.log(mapEvent.get(pointData.event_doc_id));
                historyEventPoints.push({
                  date: makeDate(pointData.results_processed),
                  event: mapEvent.get(pointData.event_doc_id),
                  fight: pointData.p_game_name ?? '',
                  mola_sub_type: pointData.mola_sub_type,
                  outcome: pointData.points_won_outcome ?? 0,
                  method: pointData.points_won_method ?? 0,
                  rounds: pointData.points_won_rounds ?? 0,
                  total_point: pointData.points_won,
                });
              }
            });
            console.log(historyEventPoints);
            console.log('after',historyEventPoints.length);
            console.log('stop load', stopLoadMore)
            commit('setState', {
              historyPointArr: historyEventPoints,
              lastHistory: lastVisibleHistory,
              historyLoading: false,
              stopLoadMoreHistory: stopLoadMore
            });
          })
          .catch((err) => {
            console.log(err);
            commit('setState', {
              historyLoading: false,
              historyErrorMessage:
                err.message ?? 'Error! Something Went Wrong!',
            });
          });
      }
    },
    // ###############################################################
    // ###################### SWAP REQUESTS ##########################
    // ###############################################################
    loadSwapRequests ({commit}, payload) {
      commit('setLoading', true)
      let today = new Date().getTime()
        let query = db.collection('swaprequests').where('uid', '==', payload.uid).orderBy('created', 'desc').limit(payload.limit)
        query.get()
            .then(snapshot => {
            if (snapshot.empty) {
                console.log('No matching Swap Requests.')
                commit('setUserSwapRequestsEmpty', true)
                return
            }
            var swapRequestList = []
            snapshot.forEach(doc => {
                console.log(doc.id, '=>', doc.data())
                var obj = doc.data()
                obj.checkTime = Math.round(today / 1000 + 1800) // 1 Hour to prevent to much Firebase loading/reading
                swapRequestList.push(obj)
                // var inArray = eventList.findIndex(item => item.id === obj.id) > -1
            })
            console.log('##### Predictions List loaded ######')
            console.log(swapRequestList)
            commit('setUserSwapRequestsEmpty', false)
            commit('setUserSwapRequestsArr', swapRequestList)
        }, err => {
            console.log(`Encountered error: ${err}`)
        })
    },
    createSwapRequest ({commit}, payload) {
      commit('setLoading', true)
      db.collection('swaprequests').doc(payload.id).set(payload)
      .then(() => {
        console.log('Swap Request in bucket created.')
      })
      .catch(error => {
        console.log(error)
      })
    },
    cancelSwapRequest ({commit}, payload) {
      commit('setLoading', true)
      db.collection('swaprequests').doc(payload.id).update(payload)
      .then(() => {
        console.log('Swap Request in bucket cancelled.')
      })
      .catch(error => {
        console.log(error)
      })
    }
  },
  getters: {
  },
};