export default {
  state: {
    itemCart:
      localStorage.getItem('itemCart-mola-catalog-staging') === null
        ? []
        : JSON.parse(localStorage.getItem('itemCart-mola-catalog-staging')),
  },
  mutations: {
    setState(state, payload) {
      var keys = Object.keys(payload);
      keys.forEach((key) => {
        state[key] = payload[key];
      });
    },
    setStateLocalStorage(state, payload) {
      var keys = Object.keys(payload);
      keys.forEach((key) => {
        state[key] = payload[key];
        localStorage.setItem(
          `${state[key]}-mola-catalog-staging`,
          JSON.stringify(payload)
        );
      });
    },
  },
  actions: {
    async addToCart({ commit, state }, payload) {
      console.log(payload);
      if (state.itemCart.length === 0) {
        commit('setStateLocalStorage', {
          itemCart: [payload],
        });
        return;
      }
      const existingCart = state.itemCart;
      const itemIndexInCart = existingCart.findIndex((item) => item.id === payload.id);
      if (itemIndexInCart > -1) {
        existingCart[itemIndexInCart].qty += payload.qty;
      }
      else{
        existingCart.push(payload);
      }
      commit('setStateLocalStorage', {
        itemCart: existingCart,
      });
      return;
    },
  },
  getters: {},
};
