var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"mx-auto",staticStyle:{"max-width":"1200px"}},[(_vm.isLoading)?_c('v-row',{staticClass:"pa-md-2 pa-0"},_vm._l((6),function(index){return _c('v-col',{key:index,staticClass:"mx-auto mx-md-0 px-2 px-md-0",attrs:{"cols":"12","md":"2"}},[_c('ShopCardSkeletonLoader',{attrs:{"dark-mode":_vm.dark}})],1)}),1):_c('v-row',{staticClass:"py-0 pa-md-2 px-1",attrs:{"align":"stretch","id":"first"}},[(_vm.$vuetify.breakpoint.mobile)?_c('v-col',{staticClass:"pl-0 pr-2",attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"px-0",attrs:{"icon":"","color":"#C40621","large":""},on:{"click":function($event){_vm.dialog=true}}},[_c('v-icon',[_vm._v("mdi-filter-variant")])],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.mobile)?_c('v-col',{staticClass:"pr-0 pl-4 d-flex align-center",attrs:{"cols":"11"}},[_c('v-sheet',{staticClass:"mx-auto",staticStyle:{"background-color":"#000 !important"},attrs:{"max-width":"100%"}},[_c('v-slide-group',{attrs:{"active-class":"primary white--text","show-arrows":!_vm.$vuetify.breakpoint.mobile},model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}},_vm._l((_vm.category),function(item){return _c('v-slide-item',{key:item.id,scopedSlots:_vm._u([{key:"default",fn:function({ active, toggle }){return [_c('v-btn',{staticClass:"mx-1",attrs:{"input-value":active,"depressed":"","rounded":""},on:{"click":[toggle,function($event){$event.stopPropagation();return _vm.categoryClicked(item.name)}]}},[_vm._v(" "+_vm._s(item.lang[_vm.getLanguage])+" ")])]}}],null,true)})}),1)],1)],1):_vm._e(),(_vm.catalogs.length > 0)?_c('v-row',_vm._l((_vm.catalogs),function(item,index){return _c('v-col',{key:index,staticClass:"px-0",attrs:{"cols":"12","md":"2"}},[_c('ShopCard',{attrs:{"item-data":{
                  image: item.url,
                  title: item.title,
                  type: item.item_type,
                  category: item.category,
                  price: item.item_price_mola_points,
                  stock: Number.parseInt(item.item_quantity),
                  redeemed: Number.parseInt(item.redeemed ?? 0),
                  description: item.story,
                  slug: item.slug ?? item.id,
                  id: item.id,
                  rating: item.rating,
                  isHideRedeem: item.isHideRedeem ?? false,
                  isReward: item.isReward ?? false,
                  comingSoonDate: item.comingSoonDate ?? null,
                  images: item.images ?? [],
                  variants: item.variants ?? [],
                  variantDetail: item.variantDetail ?? null,
                  isVariant: item.isVariant ?? false,
                  imageAspectRatio: '0.65'//item.imageAspectRatio ?? 1
              },"drawer":_vm.drawer,"dark-mode":_vm.dark,"id":item.slug,"isMobileDevice":_vm.isMobileDevice,"windowWidth":_vm.windowWidth},on:{"cart":_vm.addToCart,"redeem":_vm.redeemNow,"choose":_vm.shopCardClicked}})],1)}),1):_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticStyle:{"border":"1px solid rgb(23, 40, 84)"},attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.lang[_vm.getLanguage].PRODUCT_404))])],1)],1)],1),_c('br'),_c('br')],1),(_vm.redeemProduct !== null)?_c('RedeemNow',{attrs:{"is-open":_vm.isShowRedeem,"product":_vm.redeemProduct},on:{"close":_vm.showRedeem}}):_vm._e(),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{staticClass:"pb-4",attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.lang[_vm.getLanguage].FILTER))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.lang[_vm.getLanguage].APPLY)+" ")])],1)],1),_c('v-row',{staticClass:"mx-auto"},[_c('v-col',{attrs:{"cols":"12"}},[_c('SideFilter')],1)],1),_c('v-card-actions')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }