'use strict';
// const ipfsClient = require('ipfs-http-client')
import axios from 'axios';
// const request = require('request')
// import store from '@/store/index'

var MFP_API;
if (process.env.VUE_APP_ENVIRONMENT === 'production') {
  console.log('API PRODUCTION ENVIRONMENT');
  MFP_API = process.env.VUE_APP_MFP_API;
} else if (process.env.VUE_APP_ENVIRONMENT === 'staging') {
  console.log('API TEST ENVIRONMENT');
  MFP_API = process.env.VUE_APP_MFP_API_TEST;
} else {
  console.log('API LOCAL ENVIRONMENT');
  MFP_API = process.env.VUE_APP_MFP_API_LOCAL;
}

var CATALOG_API;
if (process.env.VUE_APP_ENVIRONMENT === 'production') {
  console.log('API PRODUCTION ENVIRONMENT');
  CATALOG_API = process.env.VUE_APP_CATALOG_API;
} else if (process.env.VUE_APP_ENVIRONMENT === 'staging') {
  console.log('API TEST ENVIRONMENT');
  CATALOG_API = process.env.VUE_APP_CATALOG_API_TEST;
} else {
  console.log('API LOCAL ENVIRONMENT');
  CATALOG_API = process.env.VUE_APP_CATALOG_API_LOCAL;
}

let getters = null;
class MolaMfpAPI {
  constructor() {
    getters = getters || this;
    return getters;
  }

  // ####################################################################
  // ######################### ADD FILES ################################
  // ####################################################################

  getFighterDetails() {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return axios
      .get(MFP_API + 'mfp/fighter', config)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getFighterDetailsMain(name) {
    // const requestBody = {
    //     name: name,
    //   };
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return axios
      .get(
        'https://liveapi.yext.com/v2/accounts/me/answers/vertical/query?experienceKey=answers-en&api_key=850a88aeb3c29599ce2db46832aa229f&v=20220511&version=PRODUCTION&locale=en&input=' +
          name +
          '&verticalKey=athletes&limit=1',
        config
        /* 'https://liveapi.yext.com/v2/accounts/me/answers/vertical/query?v=20220511&api_key=850a88aeb3c29599ce2db46832aa229f&experienceKey=answers-en&locale=en&filters=%7B%22name%22%3A%7B%22%24eq%22%3A%22' + name + '%22%7D%7D&verticalKey=athletes&input=' + name,
        config */
      )
      .then((response) => {
        return response;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getFighterDetailsRetry(name) {
    // const requestBody = {
    //     name: name,
    //   };
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return axios
      .get(
        /* 'https://liveapi.yext.com/v2/accounts/me/answers/vertical/query?experienceKey=answers-en&api_key=850a88aeb3c29599ce2db46832aa229f&v=20220511&version=PRODUCTION&locale=en&input=' + name + '&verticalKey=athletes&limit=1',
          config */
        'https://liveapi.yext.com/v2/accounts/me/answers/vertical/query?v=20220511&api_key=850a88aeb3c29599ce2db46832aa229f&experienceKey=answers-en&locale=en&verticalKey=athletes&input=' +
          name +
          '&filters=%7B%22name%22%3A%7B%22%24eq%22%3A%22' +
          name +
          '%22%7D%7D',
        config
      )
      .then((response) => {
        return response;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getNumJobs() {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return axios
      .get(MFP_API + 'mola/numjobs', config)
      .then((response) => {
        // console.log(response)
        return response;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  updatePosterAccount(id) {
    const requestBody = {
      id: id,
    };
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return axios
      .post(MFP_API + 'mola/updateaccount', requestBody, config)
      .then((response) => {
        // console.log(response)
        return response;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getAdminDashboard() {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return (
      axios
        .get(CATALOG_API + `admin/dashboard`, config)
        .then((response) => {
          return response;
        })
        .catch((e) => {
          console.log(e);
        })
    );
  }

  // ####################################################################
  // ################### MOLA SUBSCRIBER API  CALLS #####################
  // ####################################################################
  getSubscriptionStatus(email) {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return axios
      .get(MFP_API + 'users/mola?email=' + email, config)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((e) => {
        console.log(e);
      });
  }
  // ####################################################################
  // ################### GET MOLA POINTS FROM LEDGER ####################
  // ####################################################################
  async getMolaPointsFromLedger(uid) {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return await axios.get(MFP_API + 'users/molapoints?uid=' + uid, config);
  }

  async getTotalPointsFromLedger(uid) {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return await axios.get(MFP_API + 'points/get-points?uid=' + uid, config);
  }
  // ####################################################################
  // ################### GET MOLA POINTS FROM LEDGER ####################
  // ####################################################################
  getServerTime() {
    // const requestBody = {
    //     name: name,
    //   };
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return (
      axios
        // http://worldtimeapi.org/api/timezone/Etc/UTC
        .get(MFP_API + 'users/getservertime', config)
        .then((response) => {
          return response;
        })
        .catch((e) => {
          console.log(e);
        })
    );
  }

  getUserRecap(uid) {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return (
      axios
        // http://worldtimeapi.org/api/timezone/Etc/UTC
        .get(MFP_API + `users/getuserrecap?uid=${uid}`, config)
        .then((response) => {
          return response;
        })
        .catch((e) => {
          console.log(e);
        })
    );
  }

  async deductPointInMFP(payload) {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    const body = {
      order_id: payload.order_id,
      item_id: payload.item_id,
      uid: payload.uid,
      points: payload.points,
      qty: payload.qty,
      price_per_item: payload.price,
      item_name: payload.item_name,
    };

    return axios
      .post(MFP_API + 'points/deduct-point', body, config)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async redeemItem(payload) {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    const body = {
      itemId: payload.itemId,
      uid: payload.uid,
      payload: payload?.data
    };

    return axios
      .post(CATALOG_API + 'order/redeem-item', body, config)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        console.log(e);
      });
  }
   
  // Redeem history from MFP
  async getRedeemHistory(uid){
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return await axios.get(
      MFP_API + 'points/get-redeem-history?uid=' + uid,
      config
    );
  }

  async getSession(id){
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return await axios.get(
      MFP_API + 'users/session?sessionId=' + id,
      config
    );
  }

  // change status in Points ledger MFP
  async changeStatus(payload) {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    const body = {
      order_id: payload.order_id,
      status: payload.status,
    };

    return axios
      .post(MFP_API + 'points/change-status', body, config)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  // sync profile
  async syncProfile(payload) {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return axios
      .post(MFP_API + 'profile/sync-profile', payload, config)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  // ####################################################################
  // ####################### MOLA CATALOG API CALLS  ####################
  // ####################################################################

  async sendOrderEmail(payload) {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };

    return axios
      .post(CATALOG_API + 'mailer/order-created', payload, config)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async sendOrderUpdateEmail(payload) {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };

    return axios
      .post(CATALOG_API + 'mailer/order-updated', payload, config)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async getRegion() {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };

    return axios
      .get(CATALOG_API + 'users/getregion', config)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async migrateItem(bucketName, docId, region, includeImage) {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };

    return axios
      .get(CATALOG_API + 
        `migrate/item?bucket=${bucketName}&id=${docId}&region=${region}&include_image=${includeImage}`,
        config)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        console.log(e);
      });
  }
}
export default new MolaMfpAPI();
