import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import FIREBASE_CONFIG from './config/firebase';
import FIREBASE_CONFIG_UK from './config/firebaseUK';
import firebase from 'firebase/app';
import bus from './bus/index';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/auth';
import VueClipboards from 'vue-clipboards';
import VueScrollTo from 'vue-scrollto';
import VueSocialSharing from 'vue-social-sharing';
import VueCryptojs from 'vue-cryptojs'

Vue.prototype.$bus = bus;
import VueConnectionListener from 'vue-connection-listener';
import MolaMfpAPI from './clients/MolaMfpAPI';
const connectionListener = new VueConnectionListener(bus);

// // // Prevent console.log in production but keep it in local for debugging
// if (process.env.VUE_APP_ENVIRONMENT === "production") {
//   // Override console.log in production
//   console.log = function() {
//     // Do nothing or handle differently in production
//   };
// } else {
//   console._log = console.log; // Preserve the original console.log method
//   console.log = function() {
//     // Call the original console.log method with the arguments
//     console._log.apply(console, arguments);
//   };
// }

// if([null, undefined, ''].includes(localStorage.getItem('mola-catalog-region'))){
  //let region = 'AS';
  localStorage.setItem('fetching-region', true)
  MolaMfpAPI.getRegion().then((resp) => {
    const region = resp.data?.continentCode ?? 'AS'
    console.log('region main', region);
    localStorage.setItem('mola-catalog-region', region)
  })
// }

Vue.use(VueSocialSharing);
Vue.use(VueClipboards);
Vue.use(VueCryptojs);
Vue.use(VueScrollTo, {
  container: 'body',
  duration: 1000,
  easing: 'ease',
  offset: -80,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});

Vue.config.productionTip = false;
let app = firebase.initializeApp(FIREBASE_CONFIG);
var secondary = firebase.initializeApp(FIREBASE_CONFIG_UK, "secondary");

export const db = firebase.firestore();
export const dbId = firebase.firestore();
export const dbUK = secondary.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage(app).ref();
export const storageLondon = firebase.storage(secondary).ref();

  if (app) {
    new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
      created() {
        console.log(connectionListener);
        connectionListener.register();
      },
      destroyed() {
        console.log(connectionListener);
        connectionListener.unregister();
      },
    }).$mount('#app');
  }
