<template>
  <!-- <v-container :class="drawer ? 'mt-8 mx-md-8' : 'mt-8 mx-md-16'"> -->
  <v-container class="mx-auto" style="max-width: 1200px;">
    <v-row class="pa-md-2 pa-0" v-if="isLoading">
      <v-col cols="12" md="2" v-for="index in 6" :key="index" class="mx-auto mx-md-0 px-2 px-md-0">
        <ShopCardSkeletonLoader :dark-mode="dark"></ShopCardSkeletonLoader>
      </v-col>
    </v-row>
    <v-row class="py-0 pa-md-2 px-1" align="stretch" v-else id="first">
        <!-- Cart: {{ cartLength }} -->
        <v-col cols="1" class="pl-0 pr-2" v-if="$vuetify.breakpoint.mobile">
          <v-btn
              icon
              color="#C40621"
              class="px-0"
              large
              @click="dialog=true"
            >
              <v-icon>mdi-filter-variant</v-icon>
            </v-btn>
        </v-col>
        <v-col cols="11" class="pr-0 pl-4 d-flex align-center"
          v-if="$vuetify.breakpoint.mobile"
        >
          <v-sheet
          class="mx-auto"
          style="background-color: #000 !important;"
          max-width="100%"
        >
          <v-slide-group
            v-model="selectedCategory"
            active-class="primary white--text"
            :show-arrows="!$vuetify.breakpoint.mobile"
          >
            <v-slide-item
              v-for="item in category"
              :key="item.id"
              v-slot="{ active, toggle }"
            >
              <v-btn
                class="mx-1"
                :input-value="active"
                depressed
                rounded
                @click="toggle"
                @click.stop="categoryClicked(item.name)"
              >
                {{item.lang[getLanguage]}}
              </v-btn>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
        </v-col>
        <v-row v-if="catalogs.length > 0">
          <v-col cols="12" md="2" v-for="(item,index) in catalogs" :key="index" class="px-0">
            <ShopCard
                :item-data="{
                    image: item.url,
                    title: item.title,
                    type: item.item_type,
                    category: item.category,
                    price: item.item_price_mola_points,
                    stock: Number.parseInt(item.item_quantity),
                    redeemed: Number.parseInt(item.redeemed ?? 0),
                    description: item.story,
                    slug: item.slug ?? item.id,
                    id: item.id,
                    rating: item.rating,
                    isHideRedeem: item.isHideRedeem ?? false,
                    isReward: item.isReward ?? false,
                    comingSoonDate: item.comingSoonDate ?? null,
                    images: item.images ?? [],
                    variants: item.variants ?? [],
                    variantDetail: item.variantDetail ?? null,
                    isVariant: item.isVariant ?? false,
                    imageAspectRatio: '0.65'//item.imageAspectRatio ?? 1
                }"
                :drawer="drawer"
                :dark-mode="dark"
                @cart="addToCart"
                @redeem="redeemNow"
                @choose="shopCardClicked"
                :id="item.slug"
                :isMobileDevice="isMobileDevice"
                :windowWidth="windowWidth"
            />
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12">
            <v-card elevation="0" style="border: 1px solid rgb(23, 40, 84);">
              <v-card-title>{{ lang[getLanguage].PRODUCT_404 }}</v-card-title>
            </v-card>
          </v-col>
        </v-row>
        <br />
        <br />
        <!-- <v-col cols="12" md="3">
        <ShopCard
            :item-data="{
                image: '/img/home/home_bg_mobile_03.webp',
                title: 'UFC Ticket',
                type: 'Digital Product',
                cost: 1400,
                stock: 20,
            }"
            :dark-mode="dark"
        />
        </v-col>
        <v-col cols="12" md="3">
        <ShopCard
            :item-data="{
                image: '/img/home/card_3_dark.jpeg',
                title: 'Mola Digital Ticket Discount 70%',
                type: 'Digital Product',
                cost: 250,
                stock: 20,
            }"
            :dark-mode="dark"
        />
        </v-col>
        <v-col cols="12" md="3">
        <ShopCard
            :item-data="{
                image: '/img/home/home_bg_mobile_02.webp',
                title: 'Mola Champion Statue',
                type: 'Physical Product',
                cost: 8888,
                stock: 20,
            }"
            :dark-mode="dark"
        />
        </v-col> -->
        <!-- <ProductDetail
        v-if="choosenProduct !== null"
        :is-open="isShowProduct"
        :product="choosenProduct"
        :dark="dark"
        @close="showProduct"
        @cart="addToCart"
        @redeem="redeemNow"
      ></ProductDetail> -->
    </v-row>
    
    <RedeemNow v-if="redeemProduct !== null" :is-open="isShowRedeem" :product="redeemProduct" @close="showRedeem"></RedeemNow>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
          class="pb-4"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ lang[getLanguage].FILTER }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="dialog = false"
            >
              {{ lang[getLanguage].APPLY }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
          <v-row class="mx-auto">
            <v-col cols="12">
              <SideFilter />
            </v-col>
          </v-row>
          <v-card-actions></v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ShopCard from './ShopCard.vue';
import ShopCardSkeletonLoader from './ShopCardSkeletonLoader.vue';
import { scroller } from 'vue-scrollto/src/scrollTo';
// import ProductDetail from './ProductDetail.vue';
import RedeemNow from './RedeemNow.vue';
import SideFilter from './SideFilter.vue';
export default {
  props: {
    dark: Boolean,
    windowWidth: Number,
    windowHeight: Number,
    drawer: Boolean,
    isMobileDevice: Boolean
  },
  components: {
    ShopCard,
    ShopCardSkeletonLoader,
    // ProductDetail,
    RedeemNow,
    SideFilter
  },
  data() {
    return {
      isShowProduct: false,
      choosenProduct: null,
      isShowRedeem: false,
      redeemProduct: null,
      selectedCategory: null,
      dialog: false
    }
  },
  computed:{
    lang(){
      return this.$store.state.lang;
    },
    getLanguage(){
      return this.$store.state.current_language
    },
    catalogs(){
      return this.$store.state.catalog.itemCatalog;
    },
    lastVisible(){
      return this.$store.state.catalog.lastVisible;
    },
    lastVisibleFrom(){
      return this.$store.state.catalog.lastVisibleFrom;
    },
    isLoading(){
      return this.$store.state.catalog.isLoading;
    },
    cartLength(){
      return this.$store.state.cart.itemCart.length;
    },
    cart(){
      return this.$store.state.cart.itemCart;
    },
    category(){
      return this.$store.state.catalog.categories;
    },
    productDetail(){
      return this.$store.state.catalog.productDetail;
    }
  },
  watch:{
    productDetail(v){
      if(v !== null){
        this.choosenProduct = v;
        this.isShowProduct = true
      }
    }
  },  
  created(){
    console.log(this.category)
    if(this.catalogs.length === 0){
      this.$store.dispatch("getCatalogs", {
        limit: 2000,
        search: '',
        filter: '',
        lastVisible: null,
        lastVisibleFrom: '',
        next: false
      });
    }
    const firstScrollTo = scroller();
    if(this.productDetail !== null && this.catalogs.length !== 0){
      setTimeout(() => {
        firstScrollTo(`#${this.productDetail.slug}`, 500, { offset: -80 });
      }, 500);
    }
    else{
      setTimeout(() => {
        firstScrollTo(`#hometoolbar`, 500, { offset: -80 });
      }, 200);
    }
  },
  methods:{
    showProduct(value) {
      this.isShowProduct = value;
      if(value === false){
        this.choosenProduct = null;
        this.$store.dispatch('setProductDetail', null);
      }
    },
    showRedeem(value){
      this.isShowRedeem = value;
      if(value === false){
        this.redeemProduct = null; 
      }
    },
    addToCart(value) {
      console.log("cart", value);
      value.qty=1;
      this.$store.dispatch("addToCart", value);
    },
    redeemNow(value){
      this.redeemProduct = value;
      this.showRedeem(true);
    },
    shopCardClicked(value){
      this.$store.dispatch('setProductDetail', value);
      this.$router.push('/catalog/' + value.slug);
      // this.choosenProduct = value;
      // this.isShowProduct = true
    },
    categoryClicked(item){

      this.choosenCategory = this.choosenCategory === item ? '' : item;
      if(this.choosenCategory !== ''){
        this.$store.dispatch("getCatalogs", {
          limit: 2000,
          search: '',
          filter: this.choosenCategory,
          lastVisible: null,
          lastVisibleFrom: '',
          next: false
        });
      }else{
        this.$store.dispatch("getCatalogs", {});
      }
    }
  }
};
</script>